import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import { FullLayoutComponent, SimpleLayoutComponent} from './layout/containers';
import {PublicQuoteLayoutComponent} from './layout/containers/public-quote-layout';
import {DashboardComponent} from './modules/core/dashboard/dashboard.component';
import {DiscountsComponent} from './modules/core/discounts/discounts.component';
import { P403Component } from './modules/security/components/403.component';
import { P500Component } from './modules/security/components/500.component';
import { P501Component } from './modules/security/components/501.component';
import { LoginComponent } from './modules/security/components/login.component';

// Guards
import {AuthGuard} from './modules/security/guards/auth.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '',
    component: FullLayoutComponent,
    children: [
      {
        path: 'dashboard', component: DashboardComponent,
        data: {title: 'Dashboard'},
        canActivate: [AuthGuard],
      },
      {
        path: 'develops',
        data: {title: 'Develops'},
        // component: DevelopsComponent,
        children: [
          {
            path: 'palais',
            data: {title: ''}, //data: {title: 'Palais Tulum'},
            children: [
              {
                path: 'inventory',
                data: {title: 'Inventory'},
                loadChildren: () => import('./modules/inventory/inventory.module').then((m) => m.InventoryModule),
              },
            ],
          },
        ],
      },
      {
        path: 'core',
        loadChildren: () => import('./modules/core/core.module').then((m) => m.CoreModule),
      },
      {
        path: 'commissions',
        loadChildren: () => import('./modules/commissions/commissions.module').then((m) => m.CommissionsModule),
      },
      {
        path: 'customers',
        loadChildren: () => import('./modules/customers/customers.module').then((m) => m.CustomersModule),
      },
      {
        path: 'manageusers',
        loadChildren: () => import('./modules/users/users.module').then((m) => m.UsersModule),
      },
      {
        path: 'email-templates',
        loadChildren: () => import('./modules/email-templates/email-templates.module').then((m) => m.EmailTemplatesModule),
      },
    ],
  },
  {
    path: '',
    component: PublicQuoteLayoutComponent,
    children: [
      {
        path: 'public',
        loadChildren: () => import('./modules/inventory/inventory.module').then((m) => m.InventoryModule),
      },
      {
        path: 'public-quote/discounts',
        component: DiscountsComponent,
      },
    ],
  },
  {
    path: 'security',
    component: SimpleLayoutComponent,
    children: [
      { path: 'login', component: LoginComponent},
      { path: '403',   component: P403Component},
      { path: '500',   component: P500Component},
      { path: '501',   component: P501Component}, // MANTENIMIENTO
    ],
  },
  {
    path: '**',
    redirectTo: 'security/500',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
