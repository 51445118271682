import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

// declare const BackendConfig: any;

@Injectable()
export class BaseService {

  public apiUrl: string;

  constructor(private _http: HttpClient) {


   //==================== Directo al api publicada en internet==============================
   // this.apiUrl = environment.baseUrl + environment.root;

     //===========================Desarrollo Only===========================================
      if (environment.baseUrl != 'https://api.master-dev.hosttate.com') {
            this.apiUrl = environment.baseUrl + ':' + environment.port + environment.root;
         } else {
            this.apiUrl = environment.baseUrl + environment.root;
         }
      //====================================================================================
  }

  /**
   * @method Get
   * @description Servicio genérico para hacer peticiones 'Get' al backend.
   */
  Get(service: string) {
    const _headers = new HttpHeaders();
    const headers = _headers.append('Content-Type', 'application/json');

    return this._http.get(this.apiUrl + '/' + service, { headers: headers });
  }

  /**
   * @method Post
   * @description Servicio genérico para hacer peticiones 'Post' al backend.
   * @param serviceUrl: url del servicio solicitado.
   * @param _param: parametros del servicio.
   */
  Post(service: string, _params: any) {
    const params = JSON.stringify(_params);
    const _headers = new HttpHeaders();
    const headers = _headers.append('Content-Type', 'application/json');

    return this._http.post(this.apiUrl + '/' + service, params, { headers: headers });
  }

  /**
   * @method BinaryPost
   * @description Servicio genérico para hacer peticiones 'Post' para enviar archivos ('multipart/form-data') al backend.
   * @param serviceUrl: url del servicio solicitado.
   * @param _param: parametros del servicio.
   */
  BinaryPost(service: string, _params: any) {
    const _headers = new HttpHeaders();
    // const headers = _headers.append('Content-Type', 'multipart/form-data');

    return this._http.post(this.apiUrl + '/' + service, _params);
  }

  /**
   * @method Put
   * @description Servicio genérico para hacer peticiones 'Put' (update) al backend.
   * @param serviceUrl: url del servicio solicitado.
   * @param _update: parametros de actualizacion del servicio.
   */
  Put(serviceUrl: string, _update: any) {
    const update = JSON.stringify(_update);
    const _headers = new HttpHeaders();
    const headers = _headers.append('Content-Type', 'application/json');

    return this._http.put(this.apiUrl + '/' + serviceUrl, update, { headers: headers });

  }

  /**
   * @method Delete
   * @description Servicio genérico para hacer peticiones 'Delete' al backend.
   * @param serviceUrl: url del servicio solicitado.
   */
  Delete(serviceUrl: string) {
    const _headers = new HttpHeaders();
    const headers = _headers.append('Content-Type', 'application/json');

    return this._http.delete(this.apiUrl + '/' + serviceUrl, { headers: headers });
  }

  /**
   * @method GetPdfViewDepartment
   * @description Servicio concreto para obtener los pdf pasando token de autenticacion
   */
  public GetPdfViewDepartment(pdf_file) {
    const _headers = new HttpHeaders();
    const headers = _headers.append('Content-Type', 'application/json');
    const service = 'get-pdf-view-department/' + pdf_file;
    return this._http.get(this.apiUrl + '/' + service, { headers: headers });
  }
}
