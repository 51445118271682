<app-header
  [notifications] = notifications
  [messages] = messages>
</app-header>

<div class="app-body">
   <app-sidebar></app-sidebar>

  <!-- Main content -->
  <main class="main">
    <!-- Breadcrumb -->
    <ol class="breadcrumb">
      <app-breadcrumbs></app-breadcrumbs>
    </ol>

    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div><!-- /.conainer-fluid -->

  </main>

  <app-aside
    [notifications] = notifications
    [messages] = messages
    (refreshNotifications) = "refreshNotifications($event)">
  </app-aside>

</div>
<app-footer></app-footer>
