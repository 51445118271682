export class DiscountDto {
  public _id: string;
  public Develop: string;
  public Percent: number;
  public Discount: number;
  public Type: string;
  public Name: string;

  constructor(
    _id: string,
    Develop: string,
    Percent: number,
    Discount: number,
    Type: string,
    Name: string) {

      this._id      = _id;
      this.Develop  = Develop;
      this.Percent  = Percent;
      this.Discount = Discount;
      this.Type     = Type;
      this.Name     = Name;
  }
}
