/*
This file has no dependencies
GUID Creation
Project: https://github.com/Steve-Fenton/TypeScriptUtilities
Author: Steve Fenton
Example usage:

var id = Guid.newGuid();

*/

export class Guid {
  static newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  static newHTMLGuid() {
    return 'ixxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  static emptyGuid() {
    return '00000000-0000-0000-0000-000000000000';
  }
}
