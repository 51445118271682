<div class="app flex-row align-items-center">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="clearfix">
          <h1 class="float-left display-3 mr-4"><strong>HOSTTATE</strong> esta actualmente en mantenimiento</h1>
          <!--<h4 class="pt-3">RIVIERALTY esta actualmente en mantenimiento!</h4>-->
          <p class="text-muted">HOSTTATE is temporarily unavailable.</p>
        </div>
      </div>
    </div>
  </div>
</div>
