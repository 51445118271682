import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { SecurityService } from '../../../services/services/security.service';

@Injectable()
export class SecurityGuard implements CanActivate {

  constructor(
    private _router: Router,
    private _securityService: SecurityService,
 ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      // console.log(next);
      // console.log('Role a chequear: ' + next.data.role);

      return this._securityService.isAllowed( next.data.roles )
        .then( (authorized: boolean) => {
            if ( authorized ) {
                  return true;
            } else {
                this._router.navigate(['/security/403']);
                // return false;
              }
        });


  }
}
