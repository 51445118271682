import { Injectable } from '@angular/core';
import { BaseService } from './base/base.service';

@Injectable({
  providedIn: 'root',
})

export class CommissionsConceptsService {

  constructor(private _baseService: BaseService ) {}

  public AddCommissionConcept( commission_concept ) {
    return this._baseService.Post('add-commission-concept', commission_concept);
  }

  public UpdateCommissionConcept(commission_conceptId, commission_concept ) {
    return this._baseService.Put('update-commission-concept/ ' + commission_conceptId, commission_concept);
  }

  public GetCommissionConcepts(active_develop_id) {
    return this._baseService.Get('get-commission-concepts/' + active_develop_id);
  }

  public GetCommissionConceptById(commission_conceptId) {
    return this._baseService.Get('get-commission-concept-by-id/' + commission_conceptId);
  }

  public RemoveCommissionConcept( commission_conceptId ) {
    return this._baseService.Delete('remove-commission-concept/' + commission_conceptId);
  }

}
