import { Injectable, EventEmitter } from '@angular/core';
import { BaseService } from './base/base.service';

@Injectable({
  providedIn: 'root',
})

export class DevelopsService {

  constructor(private _baseService: BaseService) { }

  Develops: any;

  public AddDevelops(develop) {
    return this._baseService.Post('add-develop', develop);
  }


  public GetDevelops() {
      return this._baseService.Get('get-develops');
  }

  public UpdateDevelop(developId, update) {
    return this._baseService.Put('update-develop/' + developId, update);
  }

}
