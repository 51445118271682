import { Injectable } from '@angular/core';
import { BaseService } from './base/base.service';

@Injectable({
  providedIn: 'root',
})

export class NotificationsService {

  constructor(private _baseService: BaseService ) {}

  public GetNotifications() {
    return this._baseService.Get('get-notifications/');
  }

  public RemoveNotification( notificationId ) {
    return this._baseService.Delete('remove-notification/' + notificationId);
  }

}
