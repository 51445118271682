import { Component, OnInit } from '@angular/core';
import { HostListener } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ParamsService } from './services/base/params.service';
import { DevelopsService } from './services/develops.service';

import { UserService } from './services/user.service';

@Component({
  // tslint:disable-next-line
  // selector: 'app-root',
  // templateUrl: './app.component.html'
  selector: 'body',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {
  lang: any;

  constructor(private router: Router,
    private translate: TranslateService,
    private _developsService: DevelopsService
  ) {

    // Obtención de dimensiones de pantalla
    this.getScreenSize();

    this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe((event) => {

        // console.log((event as any).url);
        ParamsService.activatedRoute = (event as any).url;   // Inicializando ruta activa para controlar GetDashboardData

        if (ParamsService.activatedRoute !== '/public/quote') {

          // Se hace una llamada al servidor(la menos costosa existente) con el credential leido para exitar al interceptor de seguridad
          this._developsService.GetDevelops().toPromise()
            .then((response) => {
              if (response) {
                this._developsService.Develops = response;
                // const develops = response.Develops;
              }
            })
            .catch((e) => {
              console.log(e.error.message);
            });
        }

      });
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    const screenHeight = window.innerHeight;
    const screenWidth = window.innerWidth;
    ParamsService.mobileScreen = screenWidth <= 768;
    // console.log(screenHeight, screenWidth);

    //   Clases de cuadrícula
    //   El sistema de cuadrícula Bootstrap 4 tiene cinco clases:
    //
    // .col-    (dispositivos extra pequeños - ancho de pantalla inferior a 576 px)
    // .col-sm- (dispositivos pequeños: ancho de pantalla igual o superior a 576 px)
    // .col-md- (dispositivos medianos: ancho de pantalla igual o superior a 768 px)
    // .col-lg- (dispositivos grandes: ancho de pantalla igual o superior a 992 px)
    // .col-xl- (dispositivos xlarge: ancho de pantalla igual o superior a 1200px)
  }

  ngOnInit() {
    // this.router.events.subscribe((evt) => {
    //   if (!(evt instanceof NavigationEnd)) {
    //     return;
    //   }
    //   window.scrollTo(0, 0)
    // });
    this.setLanguage();
  }

  setLanguage(): Observable<string> {
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('es');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.lang = this.translate.getBrowserLang();
    ParamsService.lang = this.lang;
    this.translate.use(this.lang);
    return this.lang;
  }
}
