import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: 'app-breadcrumbs.component.html',
})
export class AppBreadcrumbsComponent {
  breadcrumbs: any[];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
          this.breadcrumbs = [];
          let currentRoute = this.route.root,
          url = '';
          do {
            const childrenRoutes = currentRoute.children;
            currentRoute = null;
            // tslint:disable-next-line:no-shadowed-variable
            childrenRoutes.forEach((route) => {
              if (route.outlet === 'primary') {
                const routeSnapshot = route.snapshot;
                url += '/' + routeSnapshot.url.map((segment) => segment.path).join('/');
                this.breadcrumbs.push({
                  label: route.snapshot.data,
                  url:   url,
                });
                currentRoute = route;
              }
            });
          } while (currentRoute);
    });
  }
}
