
 import { Component, OnInit } from '@angular/core';
 import {ActivatedRoute} from '@angular/router';
 import {TranslateService} from '@ngx-translate/core';
 import {ParamsService} from '../../../services/base/params.service';
 import {SystemService} from '../../../services/system.service';
 import {UserService} from '../../../services/user.service';

 @Component({
   selector: 'app-admin-dashboard',
   templateUrl: 'dashboard.component.html',
 })
 export class DashboardComponent implements OnInit {

   public brandPrimary = '#273b45';
   public brandSuccess = '#273b45';
   public brandInfo = '#273b45';
   public brandWarning = '#273b45';
   public brandDanger = '#273b45';

   public backgroundColorChart = '#edb70c'; //'#1B283B';
   public borderColorChart ='white';  //'#edb70c'; //'white';
   public pointBackgroundColor ='white'; // '#edb70c';//'white';

   // public brandPrimary = '#20a8d8';
   // public brandSuccess = '#4dbd74';
   // public brandInfo = '#63c2de';
   // public brandWarning = '#f8cb00';
   // public brandDanger = '#f86c6b';

   dashboard_data: any;

   // lineChart1
   public lineChart1Data: any[] = [
     {
       data: [1, 2, 3, 4, 5, 6, 7],
       // label: 'Series A',
     },
   ];
   private lineChart1Labels: any[] = ['TOTAL USERS', 'ADMINS', 'SUPERVISORS', 'INTERNAL SELLERS', 'BROKERS', 'REFERREDS', 'TOTAL CUSTOMERS'];
   public  lineChart1LabelsTranslate: any[] = [];
   public  lineChart1Options: any = {
     maintainAspectRatio: false,
     scales: {
       xAxes: [{
         gridLines: {
           color: 'transparent',
           zeroLineColor: 'transparent',
         },
         ticks: {
           fontSize: 2,
           fontColor: 'transparent',
         },

       }],
       yAxes: [{
         display: false,
         ticks: {
           display: false,
           min: 0,
           max: 200,
         },
       }],
     },
     elements: {
       line: {
         tension: 0.00001,
         borderWidth: 1,
       },
       point: {
         radius: 4,
         hitRadius: 10,
         hoverRadius: 4,
       },
     },
     legend: {
       display: false,
     },
   };
   public lineChart1Colours: any[] = [
     { // grey
       backgroundColor: this.backgroundColorChart, //this.brandPrimary,  //fondo picos
       borderColor: this.borderColorChart,// 'rgba(255,255,255,.55)',
       pointBackgroundColor: this.pointBackgroundColor, // 'rgba(255,255,255,.55)',
     },
   ];
   public lineChart1Legend = false;
   public lineChart1Type = 'line';

   // lineChart2
   public lineChart2Data: any[] = [
     {
       data: [1, 2, 3, 4],
       // label: 'Series A',
     },
   ];
   private lineChart2Labels: any[] =  ['TOTAL DEPARTMENTS', 'DEPARTMENTS AVAILABLE', 'DEPARTMENTS FRACTIONATED', 'DEPARTMENTS SOLD'];
   public  lineChart2LabelsTranslate: any[] = [];
   public  lineChart2Options: any = {
     maintainAspectRatio: false,
     scales: {
       xAxes: [{
         gridLines: {
           color: 'transparent',
           zeroLineColor: 'transparent',
         },
         ticks: {
           fontSize: 2,
           fontColor: 'transparent',
         },

       }],
       yAxes: [{
         display: false,
         ticks: {
           display: false,
           min: 0,
           max: 150,
         },
       }],
     },
     elements: {
       line: {
         tension: 0.00001,
         borderWidth: 1,
       },
       point: {
         radius: 4,
         hitRadius: 10,
         hoverRadius: 4,
       },
     },
     legend: {
       display: false,
     },
   };
   public lineChart2Colours: any[] = [
     { // grey
       backgroundColor: this.backgroundColorChart, //this.brandSuccess,
       borderColor: this.borderColorChart,// 'rgba(255,255,255,.55)',
       pointBackgroundColor: this.pointBackgroundColor, // 'rgba(255,255,255,.55)',
     },
   ];
   public lineChart2Legend = false;
   public lineChart2Type = 'line';

   // lineChart3
   public lineChart3Data: any[] = [
     {
       data: [1, 2, 3],
       // label: 'Series A',
     },
   ];
   private lineChart3Labels: any[] = ['TOTAL RESERVES', 'RESERVES HOLD', 'RESERVES SOLD'];
   public  lineChart3LabelsTranslate: any[] = [];
   public  lineChart3Options: any = {
     maintainAspectRatio: false,
     scales: {
       xAxes: [{
         gridLines: {
           color: 'transparent',
           zeroLineColor: 'transparent',
         },
         ticks: {
           fontSize: 2,
           fontColor: 'transparent',
         },

       }],
       yAxes: [{
         display: false,
         ticks: {
           display: false,
           min: 0,
           max: 100,
         },
       }],
     },
     elements: {
       line: {
         tension: 0.00001,
         borderWidth: 1,
       },
       point: {
         radius: 4,
         hitRadius: 10,
         hoverRadius: 4,
       },
     },
     legend: {
       display: false,
     },
   };
   public  lineChart3Colours: any[] = [
     {
       // pointBorderWidth: 8,
       backgroundColor: this.backgroundColorChart, //this.brandWarning,
       borderColor: this.borderColorChart,// 'rgba(255,255,255,.55)',
       pointBackgroundColor: this.pointBackgroundColor, // 'rgba(255,255,255,.55)',
     },
   ];
   public lineChart3Legend = false;
   public lineChart3Type = 'line';

   // lineChart4
   // public lineChart4Data: any[] = [
   //   {
   //     data: [1, 2, 3, 4],
   //     // label: 'Series A',
   //   },
   // ];
   // public lineChart4Labels: any[] = ['TOTAL RESERVES', 'HOLD', 'FRACTIONATED', 'SOLD'];
   // public lineChart4Options: any = this.lineChart1Options;
   // public lineChart4Colours: any[] = [
   //   {
   //     // pointBorderWidth: 8,
   //     backgroundColor: this.brandDanger,
   //     borderColor: 'rgba(255,255,255,.55)',
   //   },
   // ];
   // public lineChart4Legend = false;
   // public lineChart4Type = 'line';

   // mainChart

   public mainChartElements = 27;
   public mainChartData1: number[] = [];
   public mainChartData2: number[] = [];
   public mainChartData3: number[] = [];

   public mainChartData: any[] = [
     {
       data: this.mainChartData1,
       label: 'Current',
     },
     {
       data: this.mainChartData2,
       label: 'Previous',
     },
     {
       data: this.mainChartData3,
       label: 'BEP',
     },
   ];
   /* tslint:disable:max-line-length */
   public mainChartLabels: any[] = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday', 'Monday', 'Thursday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
   /* tslint:enable:max-line-length */
   public mainChartOptions: any = {
     responsive: true,
     maintainAspectRatio: false,
     scales: {
       xAxes: [{
         gridLines: {
           drawOnChartArea: false,
         },
         ticks: {
           callback(value: any) {
             return value.charAt(0);
           },
         },
       }],
       yAxes: [{
         ticks: {
           beginAtZero: true,
           maxTicksLimit: 5,
           stepSize: Math.ceil(250 / 5),
           max: 250,
         },
       }],
     },
     elements: {
       line: {
         borderWidth: 2,
       },
       point: {
         radius: 0,
         hitRadius: 10,
         hoverRadius: 4,
         hoverBorderWidth: 3,
       },
     },
     legend: {
       display: false,
     },
   };
   public mainChartColours: any[] = [
     { // brandInfo
       backgroundColor: this.convertHex(this.brandInfo, 10),
       borderColor: this.brandInfo,
       pointHoverBackgroundColor: '#fff',
     },
     { // brandSuccess
       backgroundColor: 'transparent',
       borderColor: this.brandSuccess,
       pointHoverBackgroundColor: '#fff',
     },
     { // brandDanger
       backgroundColor: 'transparent',
       borderColor: this.brandDanger,
       pointHoverBackgroundColor: '#fff',
       borderWidth: 1,
       borderDash: [8, 5],
     },
   ];
   public mainChartLegend = false;
   public mainChartType = 'line';

   // 4 PANELES SUPERIORES ========================================================================
   public panel1_value;
   public panel1_text;

   public panel2_value;
   public panel2_text;

   public panel3_value;
   public panel3_text;

   public panel4_value;
   public panel4_text ;

   public adminRole: boolean;

   public constructor( private _systemService: SystemService,
                       private _userService: UserService,
                       private _route: ActivatedRoute,
                       private translate: TranslateService) {

     const currentUser = this._userService.CurrentUser().user;
     this.adminRole = currentUser.Role === 'SUPER_ADMIN'  || currentUser.Role === 'ADMIN' || currentUser.Role === 'SUPERVISOR';

     this.GetDashboardData();
   }

   // events
   public chartClicked(e: any): void {
     // console.log(e.active[0]._options.backgroundColor);
   }

   public chartHovered(e: any): void {
     // console.log(e);
   }

   // convert Hex to RGBA
   public convertHex(hex: string, opacity: number) {
     hex = hex.replace('#', '');
     const r = parseInt(hex.substring(0, 2), 16);
     const g = parseInt(hex.substring(2, 4), 16);
     const b = parseInt(hex.substring(4, 6), 16);

     const rgba = 'rgba(' + r + ', ' + g + ', ' + b + ', ' + opacity / 100 + ')';
     return rgba;
   }

   public random(min: number, max: number) {
     return Math.floor(Math.random() * (max - min + 1) + min);
   }

   GetDashboardData() {
     this.lineChart1LabelsTranslate = [];
     this. lineChart1Labels.forEach( async (item) => {
       this.lineChart1LabelsTranslate.push( await this.translate.get( item).toPromise() );
     });
     this.lineChart2LabelsTranslate = [];
     this. lineChart2Labels.forEach( async (item) => {
       this.lineChart2LabelsTranslate.push( await this.translate.get( item).toPromise() );
     });
     this.lineChart3LabelsTranslate = [];
     this. lineChart3Labels.forEach( async (item) => {
       this.lineChart3LabelsTranslate.push( await this.translate.get( item).toPromise() );
     });

     if ( ParamsService.activatedRoute === '/dashboard' ) {
       // console.log(ParamsService.activatedRoute);

       this._systemService.GetDashboardData().toPromise()
         .then((response) => {
           if (response) {
             // @ts-ignore
             this.dashboard_data = response.dashboard_data;

             if (this.dashboard_data) {
               let panel1_pos = 0;
               let panel2_pos = 0;
               let panel3_pos = 0;
               const panel4_pos = 0;
               const interval = 5000;

               //  ['TOTAL USERS', 'ADMINS', 'SUPERVISORS', 'INTERNAL SELLERS', 'BROKERS', 'REFERREDS', 'TOTAL CUSTOMERS'];
               this.lineChart1Data = [
                 {
                   data: [
                     this.dashboard_data.total_users,
                     this.dashboard_data.total_users_ADMIN,
                     this.dashboard_data.total_users_SUPERVISOR,
                     this.dashboard_data.total_users_INTERNAL_SELLER,
                     this.dashboard_data.total_users_BROKER,
                     this.dashboard_data.total_users_REFERRED,
                     this.dashboard_data.total_customers,
                   ],
                   // label: 'Series A',
                 }];

               // ['TOTAL DEPARTMENTS', 'DEPARTMENTS AVAILABLE', 'DEPARTMENTS FRACTIONATED', 'DEPARTMENTS SOLD'];
               this.lineChart2Data = [
                 {
                   data: [
                     this.dashboard_data.total_departments,
                     this.dashboard_data.total_departments_AVAILABLE,
                     this.dashboard_data.total_department_FRACTIONATED,
                     this.dashboard_data.total_department_SOLD,
                   ],
                   // label: 'Series A',
                 }];

               // ['TOTAL RESERVES', 'RESERVES HOLD', 'RESERVES SOLD'];
               this.lineChart3Data = [
                 {
                   data: [
                     this.dashboard_data.total_reserveDepartments,
                     this.dashboard_data.reserveDepartment_HOLD,
                     this.dashboard_data.reserveDepartment_SOLD,
                   ],
                   // label: 'Series A',
                 }];
               // this.lineChart4Data = [
               //   {
               //     data: [
               //       this.dashboard_data.total_reserves,
               //       this.dashboard_data.total_department_HOLD,
               //       this.dashboard_data.total_department_FRACTIONATED,
               //       this.dashboard_data.total_department_SOLD,
               //     ],
               //     // label: 'Series A',
               //   }];

               // INI STATE
               this.panel1_value = this.dashboard_data.total_users;
               this.panel1_text = this.lineChart1LabelsTranslate[0];

               this.panel2_value = this.dashboard_data.total_departments;
               this.panel2_text = this.lineChart2LabelsTranslate[0];

               this.panel3_value = this.dashboard_data.total_reserveDepartments;
               this.panel3_text = this.lineChart3LabelsTranslate[0];

               // this.panel4_value = this.dashboard_data.total_reserves;
               // this.panel4_text  = this.lineChart4Labels[0];

               setInterval(() => {
                 if (panel1_pos === 6) {
                   panel1_pos = 0
                 } else {
                   panel1_pos++;
                 }

                 if (panel1_pos === 0) {
                   this.panel1_value = this.dashboard_data.total_users;
                   this.panel1_text = this.lineChart1LabelsTranslate[0];
                 } else if (panel1_pos === 1) {
                   this.panel1_value = this.dashboard_data.total_users_ADMIN;
                   this.panel1_text = this.lineChart1LabelsTranslate[1];
                 } else if (panel1_pos === 2) {
                   this.panel1_value = this.dashboard_data.total_users_SUPERVISOR;
                   this.panel1_text = this.lineChart1LabelsTranslate[2];
                 } else if (panel1_pos === 3) {
                   this.panel1_value = this.dashboard_data.total_users_INTERNAL_SELLER;
                   this.panel1_text = this.lineChart1LabelsTranslate[3];
                 } else if (panel1_pos === 4) {
                   this.panel1_value = this.dashboard_data.total_users_BROKER;
                   this.panel1_text = this.lineChart1LabelsTranslate[4];
                 } else if (panel1_pos === 5) {
                   this.panel1_value = this.dashboard_data.total_users_REFERRED;
                   this.panel1_text = this.lineChart1LabelsTranslate[5];
                 } else if (panel1_pos === 6) {
                   this.panel1_value = this.dashboard_data.total_customers;
                   this.panel1_text = this.lineChart1LabelsTranslate[6];
                 }
               }, interval);

               setInterval(() => {
                 if (panel2_pos === 3) {
                   panel2_pos = 0
                 } else {
                   panel2_pos++;
                 }

                 if (panel2_pos === 0) {
                   this.panel2_value = this.dashboard_data.total_departments;
                   this.panel2_text = this.lineChart2LabelsTranslate[0];
                 } else if (panel2_pos === 1) {
                   this.panel2_value = this.dashboard_data.total_departments_AVAILABLE;
                   this.panel2_text = this.lineChart2LabelsTranslate[1];
                 } else if (panel2_pos === 2) {
                   this.panel2_value = this.dashboard_data.total_department_FRACTIONATED;
                   this.panel2_text = this.lineChart2LabelsTranslate[2];
                 } else if (panel2_pos === 3) {
                   this.panel2_value = this.dashboard_data.total_department_SOLD;
                   this.panel2_text = this.lineChart2LabelsTranslate[3];
                 }
               }, interval);

               setInterval(() => {
                 if (panel3_pos === 2) {
                   panel3_pos = 0
                 } else {
                   panel3_pos++;
                 }

                 if (panel3_pos === 0) {
                   this.panel3_value = this.dashboard_data.total_reserveDepartments;
                   this.panel3_text = this.lineChart3LabelsTranslate[0];
                 } else if (panel3_pos === 1) {
                   this.panel3_value = this.dashboard_data.reserveDepartment_HOLD;
                   this.panel3_text = this.lineChart3LabelsTranslate[1];
                 } else if (panel3_pos === 2) {
                   this.panel3_value = this.dashboard_data.reserveDepartment_SOLD;
                   this.panel3_text = this.lineChart3LabelsTranslate[2];
                 }
               }, interval);

               // setInterval( () => {
               //   if (panel4_pos === 3) {
               //     panel4_pos = 0
               //   } else {  panel4_pos++; }
               //
               //   if (panel4_pos === 0) {
               //     this.panel4_value = this.dashboard_data.total_reserves;
               //     this.panel4_text  = this.lineChart4Labels[0];
               //   } else
               //   if (panel4_pos === 1) {
               //     this.panel4_value = this.dashboard_data.total_department_HOLD;
               //     this.panel4_text  = this.lineChart4Labels[1];
               //   } else
               //   if (panel4_pos === 2) {
               //     this.panel4_value = this.dashboard_data.total_department_FRACTIONATED;
               //     this.panel4_text  = this.lineChart4Labels[2];
               //   } else
               //   if (panel4_pos === 3) {
               //     this.panel4_value = this.dashboard_data.total_department_SOLD;
               //     this.panel4_text  = this.lineChart4Labels[3];
               //   }
               // }, interval);

             } // if
           }
         })
         .catch((error) => {
           console.log(error.error.message);
         });
     }
   }

   ngOnInit(): void {
     setInterval( () => {

         this.GetDashboardData();

     }, 60000); // cada 1m

     // generate random values for mainChart
     for (let i = 0; i <= this.mainChartElements; i++) {
       this.mainChartData1.push(this.random(0, 500));
       this.mainChartData2.push(this.random(0, 500));
       this.mainChartData3.push(this.random(0, 500));
     }
   }

 }
