import { Injectable } from '@angular/core';

import { BaseService } from '../base/base.service';
import { UserService } from '../user.service';

@Injectable()
export class AuthService {

  constructor( private _baseService: BaseService,
               private _userService: UserService) {}

  isAutenticated() {
     return ( this._userService.CurrentUser() !== null );
  }

  // isAutenticated() {
  //   const promise = new Promise(
  //     (resolve, reject) => {
  //       resolve ( localStorage.getItem('cu_key') !== null );
  //      // resolve (true);
  //     }
  //   );
  //   return promise;
  // }

  login( email: string, password: string ) {

    // const sha256Password = CryptoJS.SHA256(password);
    // const encodedPassword = sha256Password.toString(CryptoJS.enc.Base64);
    // console.log('encodedPassword: ' + encodedPassword);

    return this._baseService.Post('login',
      {
          email,
          password,
        });
  }

  Logout() {
    return this._baseService.Post('logout', null);
  }

}
