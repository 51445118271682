<dx-popup
  height="auto"
  width="auto"
  [position]="position"
  [showTitle]="false"
  [showCloseButton]="false"
  [(visible)]="isOpened">

  <div *dxTemplate="let data of 'content'">
    <dx-scroll-view>
      <div
        class="card popup-card"
        [ngClass]="classes.getValue(messageStyle)">

        <div
          class="card-header-modal-alert font-weight-bold" >
          {{title}}
        </div>

        <div *ngIf="message && message !== '' " class="card-footer bg-default text-dark">
          {{message}}
        </div>
        <div *ngIf="!message || message === '' " class="card-footer bg-default text-dark">
          <div *ngIf="innerHTML && innerHTML !== ''" [innerHTML]="innerHTML"></div>
        </div>

        <div
          class="card-footer" [ngClass]="classes.getValue(messageStyle)">
          <div
            class="pull-right">
            <dx-button class="btn-avenir-celest-blue"
              *ngIf="messageType !== messageTypes.Confirmation"
              [text]="'Continue' | translate"
              (onClick)="handleMessageButtonClickedEvent(messageResults.Continue)">
            </dx-button>&nbsp;
            &nbsp;
            <dx-button class="btn-avenir-celest-blue"
              *ngIf="messageType === messageTypes.Confirmation"
              [text]="'Accept' | translate"
              (onClick)="handleMessageButtonClickedEvent(messageResults.Accept)">
            </dx-button>

            <dx-button class="dlg-button-margin btn-avenir-red"
               *ngIf="messageType === messageTypes.Confirmation"
               [text]="'Cancel' | translate"
               (onClick)="handleMessageButtonClickedEvent(messageResults.Cancel)">
            </dx-button>
          </div>
        </div>
      </div>
    </dx-scroll-view>
  </div>

</dx-popup>
