import {Component, Input, OnDestroy, OnInit} from '@angular/core';

import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {AppComponent} from '../../../app.component';
import {BaseService} from '../../../services/base/base.service';
import {ParamsService} from '../../../services/base/params.service';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
})
export class AppHeaderComponent implements OnInit, OnDestroy {

  /**
   * Arreglo de notificaciones que se actualizan cada 10s desde el componente 'FullLayoutComponent'
   */
  @Input() notifications: any[];

  /**
   * Arreglo de mensajes que se actualizan cada 10s desde el componente 'FullLayoutComponent'
   */
  @Input() messages: any[];

  subscriptions: Subscription[] = [];
  public lang: any;

  public username: string;
  public role: string;
  public email: string;
  public userImage;

  constructor( private appComponent: AppComponent,
               private translate: TranslateService,
               private _router: Router,
               private _userService: UserService,
               public _baseService: BaseService) {

    const currentUser = this._userService.CurrentUser();
    this.username  = currentUser.user.User;
    this.role      = currentUser.user.Role;
    this.email     = currentUser.user.Email;
    this.userImage = currentUser.user.Image;
  }

  ngOnInit() {
    /**
     * Obtiene cual es el idioma que tiene el navegador e inicializa la app con este.
     */
    this.lang = this.appComponent.setLanguage();

    const uploadingUserImageEventSubscription = ParamsService.uploadingUserImageEvent.subscribe((userImage) => {
      if (userImage !==  null) {
        this.userImage = userImage;
      }
    });
    this.subscriptions.push(uploadingUserImageEventSubscription);
  }

  /**
   * Se ejecuta al cambiar el idioma de la aplicación.
   * @param value
   */
  switchLanguage(value) {
    this.translate.use(value);
    this.lang = value;
    ParamsService.lang = value;
  }

  // Deslogea el usuario y limpia el localstorage
  Logout() {
    this._userService.Logout();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
