import { Injectable } from '@angular/core';
import { BaseService } from './base/base.service';

@Injectable({
  providedIn: 'root',
})

export class DiscountsService {

  constructor(private _baseService: BaseService ) {}

  public AddAlternativeDiscountsTables( developid, params ) {
    return this._baseService.Post('add-alternative-discounts-tables/' + developid, params);
  }

  public RemoveAlternativeDiscountsTables( alternativeDiscountId ) {
    return this._baseService.Delete('remove-alternative-discounts-tables/' + alternativeDiscountId);
  }

  public GetDiscountsTablesByDevelop( developid, param ) {
    return this._baseService.Post('get-discounts-tables-by-develop/' + developid, param);
  }

  public GetAlternativesDiscountsList(developid) {
    return this._baseService.Get('get-alternatives-discounts-list/' + developid);
  }

  public UpdateAlternativesDiscountParam( alternativeDiscountId, param ) {
    return this._baseService.Put('update-alternative-discount-param/' + alternativeDiscountId, param);
  }

  public UpdateDiscount( discountid, discount ) {
    return this._baseService.Put('update-discount/' + discountid, discount);
  }

}
