import {EventEmitter} from '@angular/core';
import {AlternativeDiscountDto} from '../../dto-models/alternative-discount-dto.model';
import {MessageResult} from '../../modules/shared/helpers/global-enums';

export class ParamsService {
  // Dimensión de la pantalla
  static mobileScreen: boolean;
  static lang = 'es';             // Idioma activo
  static activatedRoute = '';
  static publicQuote: boolean;    // Define si la app esta en la vista cotizador publico
  static AlternativeDiscount: AlternativeDiscountDto;

  // Para ser pasado al servicio que construye el reporte pdf
  static department: any;
  static clientReserved: any;
  static paymentForm: any;
  static downloadQuoteListPdfReportEvent  = new EventEmitter<boolean>();
  static showAllPages: boolean;
  static validatorCode: string;

  // Emisores de evento de acccion del cuadro de dialogo de componentes dinamicos
  static addEditUserDialogEvent                     = new EventEmitter<MessageResult>();
  static addEditChangeUserPasswordDialogEvent       = new EventEmitter<MessageResult>();
  static addEditCustomersComponentEvent             = new EventEmitter<any>();
  // static addEditDepartmentDialogEvent               = new EventEmitter<MessageResult>();
  static reserveDepartmentDialogEvent               = new EventEmitter<MessageResult>();
  static addEditCommissionConceptComponentEvent     = new EventEmitter<MessageResult>();
  static assignationCommissionConceptComponentEvent = new EventEmitter<MessageResult>();
  static addEditDiscountComponentEvent              = new EventEmitter<MessageResult>();

  // Definición de evento que se utiliza para reaccionar ante el cambio del nombre de la imagen de usuario
  static uploadingUserImageEvent  = new EventEmitter<string>();
}
