import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AppComponent} from '../../../app.component';
import {ParamsService} from '../../../services/base/params.service';

@Component({
  selector: 'app-public-quote-layout',
  templateUrl: './public-quote-layout.component.html',
})
export class PublicQuoteLayoutComponent implements OnInit  {

  public lang: any;

  constructor( private appComponent: AppComponent,
               private translate: TranslateService) {
  }

  ngOnInit() {
    /**
     * Obtiene cual es el idioma que tiene el navegador e inicializa la app con este.
     */
    this.lang = this.appComponent.setLanguage();
  }

  /**
   * Se ejecuta al cambiar el idioma de la aplicación.
   * @param value
   */
  switchLanguage(value) {
    this.translate.use(value);
    this.lang = value;
    ParamsService.lang = value;
  }

}
