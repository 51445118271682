export const navigation = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-graph',
    roles: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR', 'BROKER', 'INTERNAL_SELLER'],
    visible: false,
    // class: 'mt-auto',
    // variant: 'success',
    // badge: {
    //   variant: 'info',
    //   text: 'NEW'
    // }
  },
  {
    title: true,
    name: 'DEVELOPS',
    roles: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR', 'BROKER', 'INTERNAL_SELLER', 'REFERRED', 'PUBLIC'],
  },


    {
      name: 'Palais Tulum',
      icon: 'icon-layers',
      children: [
        {
          name: 'Departments',
        //  url: '/develops/palais/inventory/all',
          url: '/develops/palais/inventory/all/D-01',
          roles: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR', 'BROKER', 'INTERNAL_SELLER', 'REFERRED'],
          visible: false,
          // icon: 'assets/img/RECURSOS/icono_DEPARTAMENTOS.png',
          // icon1: 'assets/img/RECURSOS/icono_DEPARTAMENTOS.png',
          icon: 'icon-home',
        },
        {
          name: 'Departments',
          url: '/public/quote',
          roles: ['PUBLIC'],
          visible: false,
          // icon: 'assets/img/RECURSOS/icono_DEPARTAMENTOS.png',
          // icon1: 'assets/img/RECURSOS/icono_DEPARTAMENTOS.png',
          icon: 'icon-home',
        },
        {
          name: 'Reservations',
          url: '/develops/palais/inventory/reserved',
          roles: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR', 'BROKER', 'INTERNAL_SELLER', 'REFERRED'],
          visible: false,
          icon: 'icon-notebook',
        },
        {
          name: 'Reservation Requests',
          url: '/develops/palais/inventory/reservation_requests',
          roles: ['SUPER_ADMIN', 'ADMIN'],
          visible: false,
          icon: 'icon-paper-plane',
        },
      ],
    },

   //new develop
    {
      name: 'desarrollo2',
      icon: 'icon-layers',
      children: [
        {
          name: 'Departments',
          url: '/develops/palais/inventory/all/D-02',
          roles: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR', 'BROKER', 'INTERNAL_SELLER', 'REFERRED'],
          visible: false,
          // icon: 'assets/img/RECURSOS/icono_DEPARTAMENTOS.png',
          // icon1: 'assets/img/RECURSOS/icono_DEPARTAMENTOS.png',
          icon: 'icon-home',
        },
        {
          name: 'Departments',
          url: '/public/quote',
          roles: ['PUBLIC'],
          visible: false,
          // icon: 'assets/img/RECURSOS/icono_DEPARTAMENTOS.png',
          // icon1: 'assets/img/RECURSOS/icono_DEPARTAMENTOS.png',
          icon: 'icon-home',
        },
        {
          name: 'Reservations',
          url: '/develops/palais/inventory/reserved',
          roles: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR', 'BROKER', 'INTERNAL_SELLER', 'REFERRED'],
          visible: false,
          icon: 'icon-notebook',
        },
        {
          name: 'Reservation Requests',
          url: '/develops/palais/inventory/reservation_requests',
          roles: ['SUPER_ADMIN', 'ADMIN'],
          visible: false,
          icon: 'icon-paper-plane',
        },
      ],
    },


  //---/ new develop
  {
    title: true,
    name: 'COMMISSIONS',
    roles: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR', 'BROKER', 'INTERNAL_SELLER', 'REFERRED'],
  },
  {
    name: 'Commissions',
    url: '/commissions/commissions',
    roles: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR', 'BROKER', 'INTERNAL_SELLER', 'REFERRED'],
    visible: false,
    icon: 'icon-wallet',
  },
  {
    name: 'Criteries',
    url: '/commissions/commissions-concepts',
    roles: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR', 'BROKER', 'INTERNAL_SELLER', 'REFERRED'],
    visible: false,
    icon: 'icon-anchor',
  },



  // other develop
  {
    title: true,
    name: 'SETTINGS',
    roles: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR', 'BROKER', 'INTERNAL_SELLER', 'REFERRED'],
  },
  {
    name: 'Discounts',
    url: '/core/discounts',
    roles: ['SUPER_ADMIN', 'ADMIN'],
    visible: false,
    icon: 'icon-calculator',
  },
  {
    name: 'Manage Users',
    url: '/manageusers',
    roles: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR'],
    visible: false,
    icon: 'icon-user',
  },
  {
    name: 'Customers',
    url: '/customers',
    roles: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR', 'BROKER', 'INTERNAL_SELLER'],
    visible: false,
    icon: 'icon-people',
  },
  {
    name: 'Email Templates',
    url: '/email-templates',
    roles: ['SUPER_ADMIN', 'ADMIN'],
    icon: 'icon-envelope-letter', // icon-envelope-letter, icon-envelope, icon-envelope-open
  },
  {
    name: 'System Logs',
    url: '/core/system-history',
    roles: ['SUPER_ADMIN', 'ADMIN'],
    visible: false,
    icon: 'icon-list',
  },
  {
    title: true,
    name: 'TOOLS',
    roles: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR', 'BROKER', 'INTERNAL_SELLER', 'REFERRED'],
  },
  {
    name: 'INTERNAL SELLER',
    url: 'https://drive.google.com/drive/u/0/folders/1q25f9GpWBaQ-a28tEzWrtDaPhbCagVW8',
    roles: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR', 'INTERNAL_SELLER'],
    visible: false,
    icon: 'icon-cloud-download',
    variant: 'primary',
  },
  {
    name: 'BROKER',
    url: 'https://drive.google.com/drive/u/1/folders/1I825b0XRgc0vJjtvxvfRUugUtf-QHXUI',
    roles: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR', 'BROKER'],
    visible: false,
    icon: 'icon-cloud-download',
    variant: 'primary',
  },
  {
    name: 'RESOURCES',
    url: 'https://drive.google.com/drive/u/1/folders/1I825b0XRgc0vJjtvxvfRUugUtf-QHXUI',
    roles: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR', 'REFERRED'],
    visible: false,
    icon: 'icon-cloud-download',
    variant: 'primary',
  },



];


export const navigationDinamic =
{

  initialInfo: [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'icon-graph',
      roles: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR', 'BROKER', 'INTERNAL_SELLER'],
      visible: false,
    },
    {
      title: true,
      name: 'DEVELOPS',
      roles: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR', 'BROKER', 'INTERNAL_SELLER', 'REFERRED', 'PUBLIC'],
    }
  ],
  mediumInfo: [
    {
      title: true,
      name: 'COMMISSIONS',
      roles: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR', 'BROKER', 'INTERNAL_SELLER', 'REFERRED'],
    },
    {
      name: 'Commissions',
      url: '/commissions/commissions',
      roles: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR', 'BROKER', 'INTERNAL_SELLER', 'REFERRED'],
      visible: false,
      icon: 'icon-wallet',
    },
    {
      name: 'Criteries',
      url: '/commissions/commissions-concepts',
      roles: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR', 'BROKER', 'INTERNAL_SELLER', 'REFERRED'],
      visible: false,
      icon: 'icon-anchor',
    },
    {
      title: true,
      name: 'SETTINGS',
      roles: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR', 'BROKER', 'INTERNAL_SELLER', 'REFERRED'],
    },
    {
      name: 'Discounts',
      url: '/core/discounts',
      roles: ['SUPER_ADMIN', 'ADMIN'],
      visible: false,
      icon: 'icon-calculator',
    },
    {
      name: 'Manage Users',
      url: '/manageusers',
      roles: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR'],
      visible: false,
      icon: 'icon-user',
    },
    {
      name: 'Customers',
      url: '/customers',
      roles: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR', 'BROKER', 'INTERNAL_SELLER'],
      visible: false,
      icon: 'icon-people',
    },
    {
      name: 'Email Templates',
      url: '/email-templates',
      roles: ['SUPER_ADMIN', 'ADMIN'],
      icon: 'icon-envelope-letter', // icon-envelope-letter, icon-envelope, icon-envelope-open
    },
    {
      name: 'System Logs',
      url: '/core/system-history',
      roles: ['SUPER_ADMIN', 'ADMIN'],
      visible: false,
      icon: 'icon-list',
    },
    {
      title: true,
      name: 'TOOLS',
      roles: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR', 'BROKER', 'INTERNAL_SELLER', 'REFERRED'],
   }
   //,
    // {
    //   name: 'INTERNAL SELLER',
    //   url: 'https://drive.google.com/drive/u/0/folders/1q25f9GpWBaQ-a28tEzWrtDaPhbCagVW8',
    //   roles: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR', 'INTERNAL_SELLER'],
    //   visible: false,
    //   icon: 'icon-cloud-download',
    //   variant: 'primary',
    // },
    // {
    //   name: 'BROKER',
    //   url: 'https://drive.google.com/drive/u/1/folders/1I825b0XRgc0vJjtvxvfRUugUtf-QHXUI',
    //   roles: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR', 'BROKER'],
    //   visible: false,
    //   icon: 'icon-cloud-download',
    //   variant: 'primary',
    // },
    // {
    //   name: 'RESOURCES',
    //   url: 'https://drive.google.com/drive/u/1/folders/1I825b0XRgc0vJjtvxvfRUugUtf-QHXUI',
    //   roles: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR', 'REFERRED'],
    //   visible: false,
    //   icon: 'icon-cloud-download',
    //   variant: 'primary',
    // },


  ]
}



;
