import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';

import {AuthService} from '../../../services/services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private _router: Router,
              private _authService: AuthService) {
  }

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    // return this._authService.isAutenticated()
    //   .then((_isAuth: boolean) => {
    //       if (_isAuth) {
    //         return true;
    //       } else {
    //         this._router.navigate(['/security/login']);
    //         // return false;
    //       }
    //     }
    //   );

    if ( this._authService.isAutenticated() ) {
      return true;
    } else{
       this._router.navigate(['/security/login']);
      }

  }
}
