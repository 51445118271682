import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

import {TranslateService} from '@ngx-translate/core';
import * as Collections from 'typescript-collections';
import {UserDto} from '../../../../dto-models/user-dto.model';
import {DialogService} from '../../../../services/base/dialog.service';
import {ParamsService} from '../../../../services/base/params.service';
import {UserService} from '../../../../services/user.service';
import {MessageResult, MessageStyle, MessageType} from '../../helpers/global-enums';
import {Guid} from '../../helpers/guid.helper';
import {CustomDialogComponent} from '../custom-dialog/custom-dialog.component';

@Component({
  selector: 'app-add-edit-change-user-password',
  templateUrl: './add-edit-change-user-password.component.html',
})
export class AddEditChangeUserPasswordComponent implements OnInit {

  dialogId = Guid.newGuid();

  /**
   * Controla la visibilidad del poppup
   */
  @Input() visible: boolean;

  /**
   * Contiene el título del mensaje a mostrar
   * @type {string}
   */
  @Input() title = '';

  /**
   * Contiene el estilo del mensaje a mostrar
   * @type {MessageStyle}
   */
  @Input() messageStyle: MessageStyle;

  /**
   * Contiene la posición del popup
   */
  @Input() position: any = null;

  /**
   * Contiene el tipo del mensaje a mostrar
   * @type {string}
   */
  @Input() param: string;

  @Input() userDto: UserDto;

  @Output() messageResultEvent = new EventEmitter<MessageResult>();

  /**
   * Contiene las posibles clases a aplicar al mensaje en dependencia del {@link messageStyles}
   * @type {Dictionary<MessageStyle, string>}
   */
  classes = new Collections.Dictionary<MessageStyle, string>();

  messageStyles  = MessageStyle;
  messageResults = MessageResult;
  messageTypes   = MessageType;

  // CustomDialogComponent
  @ViewChild('ErrorDialog', {static: false})   errorDialog:   CustomDialogComponent;
  errorDialogMessage = '';

  isOpened = false;
  public dataSourceUsers: any[];

  public change_password_checkbox =  false;
  public change_password_code = '';

  constructor( private _userService: UserService,
               private _dialogService: DialogService,
               private translate: TranslateService) {
  }

  /**
   * Método que maneja el evento del boton Accept del AddEdit
   */
  async addEditButtonAccept(messageResult: MessageResult, event) {
    if ( event.validationGroup.validate().isValid ) {
      if (this.change_password_code === ParamsService.validatorCode) {
        ParamsService.addEditChangeUserPasswordDialogEvent.emit(messageResult);
      } else {
          const keyToTranslate =  'The validation code is not correct';
          this.errorDialogMessage  = await this.translate.get( keyToTranslate).toPromise();
          this._dialogService.open(this.errorDialog.dialogId);
      }

    } else {
        const keyToTranslate = 'Fill the required fields';
        this.errorDialogMessage  = await this.translate.get( keyToTranslate).toPromise();
        this._dialogService.open(this.errorDialog.dialogId);
    }
  }

  /**
   * Método que maneja el evento del boton Cancel del AddEdit
   */
  addEditButtonCancel(messageResult: MessageResult) {
    ParamsService.addEditChangeUserPasswordDialogEvent.emit(messageResult);
  }

  /**
   * Inicializa el componente
   */
  ngOnInit() {
    this.classes.setValue(MessageStyle.Standard, 'bg-light');
    this.classes.setValue(MessageStyle.Information, 'bg-info text-center');
    this.classes.setValue(MessageStyle.Warning, 'bg-warning text-center');
    this.classes.setValue(MessageStyle.Error, 'bg-danger text-center');
    this.classes.setValue(MessageStyle.Success, 'bg-success text-center');
  }

}
