<dx-popup
  height="auto"
  width="auto"
  [position]="{my: 'center top', at: 'center top', of: '.screen', offset: '0 50'}"
  [showTitle]="false"
  [showCloseButton]="false"
  [(visible)]="visible">

  <div *dxTemplate="let data of 'content'">
    <!-- 'both' | 'horizontal' | 'vertical' -->
    <dx-scroll-view
      [useNative]="false"
      [scrollByContent]="true"
      [scrollByThumb]="true"
      [height]="showValidationCodeSection ? 280 : 530"
      direction="vertical">
      <div class="card popup-card dx-validation-group"
      [ngClass]="classes.getValue(messageStyle)">

      <div
        class="card-header-modal font-weight-bold">
        {{title | translate}}
      </div>

      <div class="card-body">
        <div class="container" *ngIf="showValidationCodeSection">
          <div class="avenir-subtitle-regular">{{'We need to verify your email' | translate}}</div>
          <br>

          <div class="avenir-subtitle-regular">{{'Code' | translate}}</div>
          <dx-text-box style="margin-top: 5px; padding-left: 5px;  font-size: 20px"
                       [(value)]="codeEntered"
                       [width]="145"
                       [height]="50"
                       [mask]="' A A A A A A '"
                       [elementAttr]="{class: 'avenir-subtitle-information'}">
          </dx-text-box>
        </div>

        <div class="container" *ngIf="!showValidationCodeSection">
          <dx-form
            [formData]="userDto"
            [colCount]="2"
            [validationGroup]="'userDtoValidation'">

            <dxi-item
              [editorType]="'dxTextBox'"
              [label]="{ visible: true, location: 'top', alignment: 'left', showColon: false, text:'User' | translate}"
              [dataField]="'User'"
              [editorOptions]="{elementAttr: {class: 'percent-reference'}}">
              <dxi-validation-rule
                type="required"
                [message]="'is required' | translate: { param : 'User'}">
              </dxi-validation-rule>
            </dxi-item>

            <dxi-item
              [editorType]="'dxTextBox'"
              [label]="{ visible: true, location: 'top', alignment: 'left', showColon: false, text:'Name & Surname' | translate}"
              [dataField]="'Description'"
              [editorOptions]="{elementAttr: {class: 'percent-reference'}}">
              <dxi-validation-rule
                type="required"
                [message]="'is required' | translate: { param : 'Description'}">
              </dxi-validation-rule>
            </dxi-item>

            <dxi-item
              [editorType]="'dxTextBox'"
              [label]="{ visible: true, location: 'top', alignment: 'left', showColon: false, text:'Email' | translate}"
              [dataField]="'Email'"
              [editorOptions]="{disabled: operation === 'Update' && !superAdmin, elementAttr: {class: 'percent-reference'}}">
              <dxi-validation-rule type="required" [message]="'is required' | translate: { param : 'E-mail'}"></dxi-validation-rule>
              <dxi-validation-rule type="email" message="Email is invalid"></dxi-validation-rule>
            </dxi-item>

            <dxi-item
              [editorType]="'dxTextBox'"
              [label]="{ visible: true, location: 'top', alignment: 'left', showColon: false, text:'Phone' | translate}"
              [dataField]="'Phone'"
              [editorOptions]="{elementAttr: {class: 'percent-reference'}}">
            </dxi-item>

            <dxi-item
              [editorType]="'dxTextBox'"
              [label]="{ visible: true, location: 'top', alignment: 'left', showColon: false, text:'Password' | translate}"
              [dataField]="'Password'"
              [editorOptions]="{mode:'password', elementAttr: {class: 'percent-reference'}}" >
              <dxi-validation-rule type="required" [message]="'is required' | translate: { param : 'Password'}"></dxi-validation-rule>
            </dxi-item>

            <dxi-item
              [editorType]="'dxTextBox'"
              [label]="{ visible: true, location: 'top', alignment: 'left', showColon: false, text:'Confirm Password' | translate}"
              [dataField]="'ConfirmPassword'"
              [editorOptions]="{mode:'password', elementAttr: {class: 'percent-reference'}}" >
              <!--<dxi-validation-rule type="required" message="Confirm Password is required"></dxi-validation-rule>-->
            </dxi-item>

            <dxi-item
              [editorType]="'dxTextBox'"
              [colSpan]="2"
              [label]="{ visible: true, location: 'top', alignment: 'left', showColon: false, text:'Address' | translate}"
              [dataField]="'Address'"
              [editorOptions]="{elementAttr: {class: 'percent-reference'}}">
            </dxi-item>

            <dxi-item
              [editorType]="'dxTextBox'"
              [label]="{ visible: true, location: 'top', alignment: 'left', showColon: false, text:'Country' | translate}"
              [dataField]="'Country'"
              [editorOptions]="{elementAttr: {class: 'percent-reference'}}">
            </dxi-item>

          </dx-form>
          <br>
        </div>

      </div>

      <div class="card-footer-modal">
        <div class="pull-right">

          <dx-button class="btn-avenir-celest-blue"
            [text]="'Accept' | translate"
            [validationGroup]="'userDtoValidation'"
            [useSubmitBehavior]="true"
            (onClick)="addEditButtonAccept(messageResults.Accept, $event)">
          </dx-button>

          <dx-button class="dlg-button-margin btn-avenir-red"
            [text]="'Cancel' | translate"
            (onClick)="addEditButtonCancel(messageResults.Cancel)">
          </dx-button>
          &nbsp;
        </div>
      </div>
    </div>
    </dx-scroll-view>
  </div>

</dx-popup>

<app-custom-dialog
  #ErrorDialog
  [position]="{ my: 'center', at: 'center', of: 'window' }"
  [messageStyle]="messageStyles.Error"
  [messageType]="messageTypes.Default"
  [title]="'Error'"
  [message]="errorDialogMessage">
</app-custom-dialog>
