<div class="animated fadeIn">

  <div class="row">
    <div class="col-sm-6 col-lg-4">
      <div class="card text-white dashboard-card-header">
        <div class="card-body pb-0">
          <!--<div class="btn-group float-right" dropdown>-->
          <!--<button type="button" class="btn btn-transparent dropdown-toggle p-0" dropdownToggle>-->
          <!--<i class="icon-settings"></i>-->
          <!--</button>-->
          <!--<div class="dropdown-menu dropdown-menu-right" *dropdownMenu>-->
          <!--<a class="dropdown-item" href="#">Action</a>-->
          <!--<a class="dropdown-item" href="#">Another action</a>-->
          <!--<a class="dropdown-item" href="#">Something else here</a>-->
          <!--<a class="dropdown-item" href="#">Something else here</a>-->
          <!--</div>-->
          <!--</div>-->
          <h4 class="mb-0">{{panel1_value}}</h4>
          <p>{{panel1_text}}</p>
        </div>
        <div class="chart-wrapper px-3" style="height:100px;">
          <canvas baseChart class="chart"
                  [datasets]="lineChart1Data"
                  [labels]="lineChart1LabelsTranslate"
                  [options]="lineChart1Options"
                  [colors]="lineChart1Colours"
                  [legend]="lineChart1Legend"
                  [chartType]="lineChart1Type"
                  (chartClick)="chartClicked($event)">
          </canvas>
        </div>
      </div>
    </div><!--/.col-->

    <div class="col-sm-6 col-lg-4">
      <div class="card text-white dashboard-card-header">
        <div class="card-body pb-0">
          <!--<button type="button" class="btn btn-transparent p-0 float-right">-->
          <!--<i class="icon-location-pin"></i>-->
          <!--</button>-->
          <h4 class="mb-0">{{panel2_value}}</h4>
          <p>{{panel2_text}}</p>
        </div>
        <div class="chart-wrapper px-3" style="height:100px;">
          <canvas baseChart class="chart"
                  [datasets]="lineChart2Data"
                  [labels]="lineChart2LabelsTranslate"
                  [options]="lineChart2Options"
                  [colors]="lineChart2Colours"
                  [legend]="lineChart2Legend"
                  [chartType]="lineChart2Type">
          </canvas>
        </div>
      </div>
    </div><!--/.col-->

    <div class="col-sm-6 col-lg-4">
      <div class="card text-white dashboard-card-header">
        <div class="card-body pb-0">
          <!--<div class="btn-group float-right" dropdown>-->
          <!--<button type="button" class="btn btn-transparent dropdown-toggle p-0" dropdownToggle>-->
          <!--<i class="icon-settings"></i>-->
          <!--</button>-->
          <!--<div class="dropdown-menu dropdown-menu-right" *dropdownMenu>-->
          <!--<a class="dropdown-item" href="#">Action</a>-->
          <!--<a class="dropdown-item" href="#">Another action</a>-->
          <!--<a class="dropdown-item" href="#">Something else here</a>-->
          <!--</div>-->
          <!--</div>-->
          <h4 class="mb-0">{{panel3_value}}</h4>
          <p>{{panel3_text}}</p>
        </div>
        <div class="chart-wrapper" style="height:100px;">
          <canvas baseChart class="chart"
                  [datasets]="lineChart3Data"
                  [labels]="lineChart3LabelsTranslate"
                  [options]="lineChart3Options"
                  [colors]="lineChart3Colours"
                  [legend]="lineChart3Legend"
                  [chartType]="lineChart3Type">
          </canvas>
        </div>
      </div>
    </div><!--/.col-->

    <!--<div class="col-sm-6 col-lg-3">-->
      <!--<div class="card text-white dashboard-card-header">-->
        <!--<div class="card-body pb-0">-->
          <!--&lt;!&ndash;<div class="btn-group float-right" dropdown>&ndash;&gt;-->
          <!--&lt;!&ndash;<button type="button" class="btn btn-transparent dropdown-toggle p-0" dropdownToggle>&ndash;&gt;-->
          <!--&lt;!&ndash;<i class="icon-settings"></i>&ndash;&gt;-->
          <!--&lt;!&ndash;</button>&ndash;&gt;-->
          <!--&lt;!&ndash;<div class="dropdown-menu dropdown-menu-right" *dropdownMenu>&ndash;&gt;-->
          <!--&lt;!&ndash;<a class="dropdown-item" href="#">Action</a>&ndash;&gt;-->
          <!--&lt;!&ndash;<a class="dropdown-item" href="#">Another action</a>&ndash;&gt;-->
          <!--&lt;!&ndash;<a class="dropdown-item" href="#">Something else here</a>&ndash;&gt;-->
          <!--&lt;!&ndash;</div>&ndash;&gt;-->
          <!--&lt;!&ndash;</div>&ndash;&gt;-->
          <!--<h4 class="mb-0">{{panel4_value}}</h4>-->
          <!--<p>{{panel4_text}}</p>-->
        <!--</div>-->
        <!--<div class="chart-wrapper" style="height:70px;">-->
          <!--<canvas baseChart class="chart"-->
                  <!--[datasets]="lineChart4Data"-->
                  <!--[labels]="lineChart4Labels"-->
                  <!--[options]="lineChart4Options"-->
                  <!--[colors]="lineChart4Colours"-->
                  <!--[legend]="lineChart4Legend"-->
                  <!--[chartType]="lineChart4Type"-->
                  <!--(chartHover)="chartHovered($event)"-->
                  <!--(chartClick)="chartClicked($event)">-->
          <!--</canvas>-->
        <!--</div>-->
      <!--</div>-->
    <!--</div>&lt;!&ndash;/.col&ndash;&gt;-->

  </div><!--/.row-->

  <!--HASTA AQUI LOS 4 PANELES-->

  <div class="card">
    <div class="card-body">
      <br>
      <div class="row justify-content-center">

      <div class="col-sm-2 col-sm-offset-1">
        <div [routerLink]="['/develops/palais/inventory/all']" class="jumbotron" >
          <img [routerLink]="['/develops/palais/inventory/all']" class="icons-dashboard" src="assets/img/RECURSOS/cotizar.png" alt="cotizar.png"/>
          <label [routerLink]="['/develops/palais/inventory/all']" align="middle" class="label-icons-dashboard">{{'Quote' | translate}}</label>
        </div>
      </div>
      <div class="col-sm-2 col-sm-offset-1">
        <div [routerLink]="['/develops/palais/inventory/reserved']" class="jumbotron" >
          <img [routerLink]="['/develops/palais/inventory/reserved']" class="icons-dashboard" src="assets/img/RECURSOS/reserves.png" alt="reserves.png"/>
          <label [routerLink]="['/develops/palais/inventory/reserved']" align="middle" class="label-icons-dashboard">{{'Reservations' | translate}}</label>
        </div>
      </div>
      <div class="col-sm-2 col-sm-offset-2">
        <div [routerLink]="['/commissions/commissions']" class="jumbotron" >
          <img [routerLink]="['/commissions/commissions']" class="icons-dashboard" src="assets/img/RECURSOS/commissions.png" alt="commissions.png"/>
          <label [routerLink]="['/commissions/commissions']" align="middle" class="label-icons-dashboard">{{'Commissions' | translate}}</label>
        </div>
      </div>
      <div class="col-sm-2 col-sm-offset-1">
        <div [routerLink]="['/commissions/commissions-concepts']" class="jumbotron" >
          <img [routerLink]="['/commissions/commissions-concepts']" class="icons-dashboard" src="assets/img/RECURSOS/critery-by-develop.png" alt="critery.png"/>
          <label [routerLink]="['/commissions/commissions-concepts']" align="middle" class="label-icons-dashboard">{{'Criteries' | translate}}</label>
        </div>
      </div>

    </div>

      <div class="row justify-content-center">

        <div class="col-sm-2 col-sm-offset-1">
          <div [routerLink]="['/core/discounts']" class="jumbotron" >
            <img [routerLink]="['/core/discounts']" class="icons-dashboard" src="assets/img/RECURSOS/discounts.png" alt="discounts.png"/>
            <label [routerLink]="['/core/discounts']" align="middle" class="label-icons-dashboard">{{'Discounts' | translate}}</label>
          </div>
        </div>
        <div *ngIf="adminRole"  class="col-sm-2 col-sm-offset-1">
          <div [routerLink]="['/manageusers']" class="jumbotron" >
            <img [routerLink]="['/manageusers']" class="icons-dashboard" src="assets/img/RECURSOS/users.png" alt="users.png"/>
            <label [routerLink]="['/manageusers']" align="middle" class="label-icons-dashboard">{{'Manage Users' | translate}}</label>
          </div>
        </div>
        <div class="col-sm-2 col-sm-offset-2">
          <div [routerLink]="['/customers']" class="jumbotron" >
            <img [routerLink]="['/customers']" class="icons-dashboard" src="assets/img/RECURSOS/customers.png" alt="customers.png"/>
            <label [routerLink]="['/customers']" align="middle" class="label-icons-dashboard">{{'Customers' | translate}}</label>
          </div>
        </div>

        <!-- <div class="col-sm-2 col-sm-offset-2">
          <div class="jumbotron" >
            <a href="" target="_blank">
              <img  class="icons-dashboard" src="assets/img/RECURSOS/informacion.png" alt="help.png"/>

            </a>
            <label align="middle" class="label-icons-dashboard">{{'Help' | translate}}</label>

          </div>
        </div> -->

      </div>

    </div>
  </div>

  <!--<div class="card">-->
    <!--<div class="card-body">-->
      <!--<div class="row">-->

        <!--<div class="col-sm-5">-->
          <!--<h4 class="card-title mb-0">Traffic</h4>-->
          <!--<div class="small text-muted">November 2017</div>-->
        <!--</div>&lt;!&ndash;/.col&ndash;&gt;-->

        <!--<div class="col-sm-7 d-none d-md-block">-->
          <!--<button type="button" class="btn btn-primary float-right"><i class="icon-cloud-download"></i></button>-->
          <!--<div class="btn-group btn-group-toggle float-right mr-3" data-toggle="buttons">-->
            <!--<label class="btn btn-outline-secondary" [(ngModel)]="radioModel" btnRadio="Day" id="option1">Day</label>-->
            <!--<label class="btn btn-outline-secondary" [(ngModel)]="radioModel" btnRadio="Month" id="option2">Month</label>-->
            <!--<label class="btn btn-outline-secondary" [(ngModel)]="radioModel" btnRadio="Year" id="option3">Year</label>-->
          <!--</div>-->
        <!--</div>&lt;!&ndash;/.col&ndash;&gt;-->

      <!--</div>&lt;!&ndash;/.row&ndash;&gt;-->
      <!--<div class="chart-wrapper" style="height:300px;margin-top:40px;">-->
        <!--<canvas baseChart class="chart"-->
                <!--[datasets]="mainChartData"-->
                <!--[labels]="mainChartLabels"-->
                <!--[options]="mainChartOptions"-->
                <!--[colors]="mainChartColours"-->
                <!--[legend]="mainChartLegend"-->
                <!--[chartType]="mainChartType"-->
                <!--(chartHover)="chartHovered($event)"-->
                <!--(chartClick)="chartClicked($event)"></canvas>-->
      <!--</div>-->
    <!--</div>-->
  <!--</div>-->

  <!--/.card-->
</div>

<!-- <div class="card">
  <iframe height="400" src="https://www.youtube.com/embed/pzI0XnYfjmU" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</div> -->


<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#e8f4ff"
  type="ball-newton-cradle">
  <!--ball-atom ball-circus square-jelly-box ball-newton-cradle-->
  <p class="spiner-label-custom">Loading...</p>
</ngx-spinner>


