import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';

import {Subscription} from 'rxjs';
import * as Collections from 'typescript-collections';
import {CommissionConceptDto} from '../../../../dto-models/commission-concept-dto.model';
import {ParamsService} from '../../../../services/base/params.service';
import {MessageResult, MessageStyle} from '../../helpers/global-enums';

@Component({
  selector: 'app-add-edit-commission-concept',
  templateUrl: './add-edit-commission-concept.component.html',
})
export class AddEditCommissionConceptComponent implements OnInit, OnDestroy {

  /**
   * Controla la visibilidad del poppup
   */
  @Input() visible: boolean;

  /**
   * Contiene el título del mensaje a mostrar
   * @type {string}
   */
  @Input() title = '';

  /**
   * Contiene el estilo del mensaje a mostrar
   * @type {MessageStyle}
   */
  @Input() messageStyle: MessageStyle;

  @Input() develops: any[];
  @Input() commissionConceptDto: CommissionConceptDto;
  @Input() operation;

  @Output() messageResultEvent = new EventEmitter<MessageResult>();

  /**
   * Controla todas las suscripciones a Observables
   */
  subscriptions: Subscription[] = [];

  /**
   * Contiene las posibles clases a aplicar al mensaje en dependencia del {@link messageStyles}
   * @type {Dictionary<MessageStyle, string>}
   */
  classes = new Collections.Dictionary<MessageStyle, string>();
  messageStyles = MessageStyle;
  messageResults = MessageResult;

  constructor() {
  }

  /**
   * Método que maneja el evento del boton Accept del AddEdit
   */
  addEditButtonAccept(messageResult: MessageResult) {
    ParamsService.addEditCommissionConceptComponentEvent.emit(messageResult);
  }

  /**
   * Método que maneja el evento del boton Cancel del AddEdit
   */
  addEditButtonCancel(messageResult: MessageResult) {
    ParamsService.addEditCommissionConceptComponentEvent.emit(messageResult);
  }

  /**
   * Inicializa el componente
   */
  ngOnInit() {
    // console.log(this.develops)
    // console.log(this.commissionConceptDto);

    this.classes.setValue(MessageStyle.Standard, 'bg-light');
    this.classes.setValue(MessageStyle.Information, 'bg-info text-center');
    this.classes.setValue(MessageStyle.Warning, 'bg-warning text-center');
    this.classes.setValue(MessageStyle.Error, 'bg-danger text-center');
    this.classes.setValue(MessageStyle.Success, 'bg-success text-center');
  }

  public valueChanged_Active_Referred(event) {
    if ( !event.value ) {
      this.commissionConceptDto.ReferredPercent = 0.00;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
