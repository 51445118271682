
export class UserDto {
  public _id: string;
  public ParentUser: string;
  public User: string;
  public Password: string;
  public ConfirmPassword: string;
  public Description: string;
  public Email: string;
  public Role: string;
  public Image: string;
  public Active: boolean;
  public Phone: string;
  public Country: string;
  public Address: string;

  constructor( _id: string,
               ParentUser: string,
               User: string,
               Password: string,
               ConfirmPassword: string,
               Description: string,
               Email: string,
               Role: string,
               Image: string,
               Active: boolean,
               Phone: string,
               Country: string,
               Address: string) {

    this._id = _id;
    this.ParentUser = ParentUser;
    this.User = User;
    this.Password = Password;
    this.ConfirmPassword = ConfirmPassword;
    this.Description = Description;
    this.Email = Email;
    this.Role = Role;
    this.Image = Image;
    this.Active = Active;
    this.Phone = Phone;
    this.Country = Country;
    this.Address = Address;
  }
}
