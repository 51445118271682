import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';

import {DxDataGridComponent} from 'devextreme-angular';
import {Subscription} from 'rxjs';
import * as Collections from 'typescript-collections';
import {CustomerDto} from '../../../../dto-models/customer-dto.model';
import {UserDto} from '../../../../dto-models/user-dto.model';
import {ParamsService} from '../../../../services/base/params.service';
import {CustomersService} from '../../../../services/customers.service';
import {UserService} from '../../../../services/user.service';
import {MessageResult, MessageStyle} from '../../helpers/global-enums';

@Component({
  selector: 'app-reserve-department',
  templateUrl: './reserve-department.component.html',
})
export class ReserveDepartmentComponent implements OnInit, OnDestroy {

  /**
   * Contiene una referencia al componente dx-data-grid de DevExpress
   */
  @ViewChild('gridCustomers', {static: false}) gridCustomers: DxDataGridComponent;

  /**
   * Controla la visibilidad del poppup
   */
  @Input() visible: boolean;

  /**
   * Contiene el título del mensaje a mostrar
   */
  @Input() title = '';

  /**
   * Contiene el estilo del mensaje a mostrar
   * @type {MessageStyle}
   */
  @Input() messageStyle: MessageStyle;

  /**
   * Contiene el tipo del mensaje a mostrar
   * @type {string}
   */
  @Input() param: string;

  /**
   * Contiene el cliente seleccionado en el grid
   */
  @Input() customerDto: CustomerDto;

  /**
   * Contiene el objeto forma de pago del departamento seleccionado para reservar
   */
  @Input() paymentForm: any;   // (no se especifica tipo 'PaymentFormDto' porque viene 'populate' con  paymentFormType)

  @Input() adminUser: boolean;
  @Input() currentUserRole: string;

  @Output() messageResultEvent = new EventEmitter<MessageResult>();

  /**
   * Contiene las posibles clases a aplicar al mensaje en dependencia del {@link messageStyles}
   * @type {Dictionary<MessageStyle, string>}
   */
  classes = new Collections.Dictionary<MessageStyle, string>();

  messageStyles = MessageStyle;
  messageResults = MessageResult;
  isOpened = false;

  /* Controla todas las suscripciones a Observables   */
  subscriptions: Subscription[] = [];

  customers_dataSource: CustomerDto[];
  selectedCustomer: CustomerDto;
  selectedUser:     UserDto;
  userDataSource: any[];

  constructor(
    private _customerService: CustomersService,
    private _userService: UserService)  {
  } // constructor

  handleCellPrepared(e) {
    if (e.rowType === 'header') {
      e.cellElement.className = 'dx-data-grid-avenir-title';
    }
  }

  /**
   * Evento q se dispara al seleccionar una Department del grid
   */
  public handleSelectionChangedEvent(event)  {
    this.selectedCustomer = event.selectedRowsData[0];
    // console.log(this.selectedCustomer);

    // todo: ver porque no es posible esta asignacion directa
    // this.customerDto          = this.selectedCustomer;

    this.customerDto._id            = this.selectedCustomer._id;
    this.customerDto.Customer       = this.selectedCustomer.Customer;
    this.customerDto.Description    = this.selectedCustomer.Description;
    this.customerDto.Phone          = this.selectedCustomer.Phone;
    this.customerDto.Email          = this.selectedCustomer.Email;
    this.customerDto.DNI            = this.selectedCustomer.DNI;
    this.customerDto.Passport       = this.selectedCustomer.Passport;
    this.customerDto.Country        = this.selectedCustomer.Country;
    this.customerDto.Address        = this.selectedCustomer.Address;
    this.customerDto.AdmissionDate  = this.selectedCustomer.AdmissionDate;
    this.customerDto.User           = this.selectedCustomer.User;
  }

  /**
   * Método que maneja el evento del boton Accept del AddEdit
   */
  buttonAccept(messageResult: MessageResult) {
    // if ( event.validationGroup.validate().isValid ) {
      ParamsService.reserveDepartmentDialogEvent.emit(messageResult);
    // }
  }

  /**
   * Método que maneja el evento del boton Cancel del AddEdit
   */
  buttonCancel(messageResult: MessageResult) {
    ParamsService.reserveDepartmentDialogEvent.emit(messageResult);
  }

  /**
   * Inicializa el componente
   */
  ngOnInit() {

    this.GetCustomersDatasource();
    this.GetUsersDatasource();

    this.classes.setValue(MessageStyle.Standard, 'bg-light');
    this.classes.setValue(MessageStyle.Information, 'bg-info text-center');
    this.classes.setValue(MessageStyle.Warning, 'bg-warning text-center');
    this.classes.setValue(MessageStyle.Error, 'bg-danger text-center');
    this.classes.setValue(MessageStyle.Success, 'bg-success text-center');
  }

  private GetCustomersDatasource() {
    const customerServiceGetCustomersSubscription = this._customerService.GetCustomers().subscribe(
      (response) => {
        if (response) {
          // @ts-ignore
          this.customers_dataSource = response.Customers;
        }
      },
      (error) => {
        // console.log(error.error.message);
      });
    this.subscriptions.push(customerServiceGetCustomersSubscription);
  }

  private GetUsersDatasource() {
     const userServiceGetUsersSubscription = this._userService.GetUsers({Role: this.currentUserRole}).subscribe(
       (response) => {
         if (response) {
           // @ts-ignore
           this.userDataSource = response.Users;
         }
       },
       (error) => {
         console.log(error.error.message);
       });
     this.subscriptions.push(userServiceGetUsersSubscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
