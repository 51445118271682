<header class="app-header navbar">


  <button class="navbar-toggler d-lg-none" type="button" appMobileSidebarToggler>
    <span class="navbar-toggler-icon"></span>
  </button>
  <img class="logo-header" src="assets/img/logo-symbol.png" />
  <button class="navbar-toggler d-md-down-none mr-auto" type="button" appSidebarToggler>
    <span class="navbar-toggler-icon"></span>
  </button>

  <ul class="nav navbar-nav ml-auto">
    <li class="nav-item" appAsideMenuToggler>
      <a class="nav-link header-notification-icon"><i class="icon-bell"></i>
        <span *ngIf="notifications.length > 0" class="badge badge-pill badge-{{notifications[0].CcsClass}}">{{notifications.length}}</span>
      </a>
    </li>

    <!--<li class="nav-item header-notification-icon">-->
      <!--<a class="nav-link"><i class="fa fa-envelope-o"></i><span class="badge badge-pill badge-warning">{{messages.length}}</span></a>-->
    <!--</li>-->

    <li *ngIf="lang === 'en'">
      <a class="nav-link header-language" (click)="switchLanguage('es')">
        <img class='img-flags-language' src="{{'assets/img/flags/flag-EN.png'}}" [alt]="'flag-EN.png'" />
      </a>
    </li>

    <li *ngIf="lang === 'es'">
      <a class="nav-link header-language" (click)="switchLanguage('en')">
        <img class='img-flags-language' src="{{'assets/img/flags/flag-ES.png'}}" [alt]="'flag-ES.png'" />
      </a>
    </li>

    <li class="nav-item dropdown" dropdown placement="bottom right">
      <!--<a class="header-session-exp">EXP TIME: {{expirationTime}} seg</a>-->
      <a class="nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false"
         dropdownToggle (click)="false">
        <a class="header-username">{{username}}</a>
        <!--<a class="header-username-separator d-md-down-none">{{':'}}</a>-->
        <!--<a class="header-username-role d-md-down-none">{{role}}</a>-->

        <!--<img *ngIf="userImage && userImage !== null " class="img-avatar" src="data:image/png;base64,{{ userImage }}" [alt]="username"/>-->
        <img *ngIf="userImage !== ''" class="img-avatar" src="{{ _baseService.apiUrl + '/get-user-image/'+userImage }}" [alt]=userImage />
        <img *ngIf="userImage === ''" class="img-avatar" src="assets/img/avatars/DefaultUser.png" alt="User image"/>

      </a>
      <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
        <div class="dropdown-header text-center"><strong>{{"Language" | translate}}</strong></div>
        <a class="dropdown-item" (click)="switchLanguage('en')"><i class="fa fa-language"></i> {{"English" | translate}} </a>
        <a class="dropdown-item" (click)="switchLanguage('es')"><i class="fa fa-language"></i> {{"Spanish" | translate}} </a>

        <div class="dropdown-header text-center"><strong translate>{{"Account" | translate}}</strong></div>
        <a class="dropdown-item" appAsideMenuToggler ><i class="fa icon-bell"></i>
          {{"Notifications" | translate}}
          <span *ngIf="notifications.length > 0" class="badge badge-pill badge-{{notifications[0].CcsClass}}">{{notifications.length}}</span>
        </a>
        <!--<a class="dropdown-item"><i class="fa fa-envelope-o"></i> {{"Messages" | translate}}<span class="badge badge-warning">{{messages.length}}</span></a>-->
        <!--<a class="dropdown-item" href="#"><i class="fa fa-tasks"></i> Tasks<span class="badge badge-danger">{{tasks}}</span></a>-->

        <a class="dropdown-item" [routerLink]="['/core/userprofile']" ><i class="fa fa-user"></i> {{"Profile" | translate}}</a>

        <div *ngIf="role === 'SUPER_ADMIN' || role === 'ADMIN'" class="dropdown-header text-center"><strong>{{"Miscellaneous" | translate}}</strong></div>
        <a *ngIf="role === 'SUPER_ADMIN' || role === 'ADMIN'" class="dropdown-item" [routerLink]="['/core/settings']" ><i class="fa fa-wrench"></i> {{"Settings" | translate}}</a>
        <a *ngIf="role === 'SUPER_ADMIN' || role === 'ADMIN'" class="dropdown-item" [routerLink]="['/email-templates/massive-email']" ><i class="icon-envelope"></i> {{"Massive Email Option" | translate}}</a>

        <div class="dropdown-header text-center"><strong translate>{{"Session" | translate}}</strong></div>
        <a class="dropdown-item" (click)="Logout()"><i class="fa fa-lock"></i> {{"Logout" | translate}}</a>
      </div>
    </li>

    <!--<button class="navbar-toggler" type="button" appAsideMenuToggler>-->
      <!--<span class="navbar-toggler-icon"></span>-->
    <!--</button>-->
  </ul>

</header>
