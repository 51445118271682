import { Injectable } from '@angular/core';
import { BaseService } from './base/base.service';
import { HttpClient } from '@angular/common/http';
import { DevelopsService } from './develops.service';
import { DevelopDto } from '../dto-models/develop-dto.model';

@Injectable({
  providedIn: 'root',
})

export class SystemService {

  constructor(
    private _baseService: BaseService,
    private httpClient: HttpClient
  ) {}

  public GetDashboardData() {
    return this._baseService.Get('get-dashboard-data');
  }

  public GetSystemHistory() {
    return this._baseService.Get('getsystemhistory');
  }

  public GetSystemConfigurations() {
    return this._baseService.Get('get-system-configurations');
  }

  public Get_config_front() {
    return this.httpClient.get<any>('assets/config/config_front.json?'+ Math.random());
  }
}
