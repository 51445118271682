import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

import {TranslateService} from '@ngx-translate/core';
import * as Collections from 'typescript-collections';
import {UserDto} from '../../../../dto-models/user-dto.model';
import {DialogService} from '../../../../services/base/dialog.service';
import {ParamsService} from '../../../../services/base/params.service';
import {UserService} from '../../../../services/user.service';
import {MessageResult, MessageStyle, MessageType} from '../../helpers/global-enums';
import {Guid} from '../../helpers/guid.helper';
import {CustomDialogComponent} from '../custom-dialog/custom-dialog.component';

@Component({
  selector: 'app-add-edit-user',
  templateUrl: './add-edit-user.component.html',
})
export class AddEditUserComponent implements OnInit {

  dialogId = Guid.newGuid();

  /**
   * Controla la visibilidad del poppup
   */
  @Input() visible: boolean;

  /**
   * Contiene el título del mensaje a mostrar
   * @type {string}
   */
  @Input() title = '';

  /**
   * Contiene el estilo del mensaje a mostrar
   * @type {MessageStyle}
   */
  @Input() messageStyle: MessageStyle;

  /**
   * Contiene la posición del popup
   */
  @Input() position: any = null;

  /**
   * Contiene el tipo del mensaje a mostrar
   * @type {string}
   */
  @Input() param: string;

  @Input() userDto: UserDto;

  @Input() superAdmin: boolean; // INDICA QUE EL USUARIO LOGEADO ES EL USUARIO **** SUPER ADMIN *****

  @Input() operation;

  @Input() roles: string[];

  @Input() is_logged_in_user: boolean;

  @Output() messageResultEvent = new EventEmitter<MessageResult>();

  /**
   * Contiene las posibles clases a aplicar al mensaje en dependencia del {@link messageStyles}
   * @type {Dictionary<MessageStyle, string>}
   */
  classes = new Collections.Dictionary<MessageStyle, string>();

  messageStyles  = MessageStyle;
  messageResults = MessageResult;
  messageTypes   = MessageType;

  // CustomDialogComponent
  @ViewChild('ErrorDialog', {static: false})   errorDialog:   CustomDialogComponent;
  errorDialogMessage = '';

  isOpened = false;
  public dataSourceUsers: any[];

  public change_password_checkbox =  false;

  public showValidationCodeSection =  false;
  validatorCode = '';                           // CODIGO DE VALIDACION OBTENIDO DESDE EL API
  codeEntered = '';                             // CODIGO INTRODUCIDO POR EL CLIENTE

  constructor( private _userService: UserService,
               private _dialogService: DialogService,
               private translate: TranslateService) {
  }

  /**
   * Método que maneja el evento del boton Accept del AddEdit
   */
  async addEditButtonAccept(messageResult: MessageResult, event) {
    if ( !this.showValidationCodeSection ) {

      if (event.validationGroup.validate().isValid) {
        if (this.userDto.Password === this.userDto.ConfirmPassword) {

          this._userService.SendValidatorCodeEmail(this.userDto).toPromise()
            .then((response) => {
              if (response) {

                this.validatorCode = (response as any).ValidatorCode;

                // SE MUESTRA LA SECCION DE CONFIRMACION DE CODIGO DE VALIDACION RECIBIDO
                this.showValidationCodeSection = true;

              }
            })
            .catch(async (e) => {
              const keyToTranslate = e.error.message;
              this.errorDialogMessage = await this.translate.get(keyToTranslate).toPromise() + ' < ' + this.userDto.Email + ' >';
              this._dialogService.open(this.errorDialog.dialogId);
            });

        } else {
            const keyToTranslate = 'The passwords not match';
            this.errorDialogMessage = await this.translate.get(keyToTranslate).toPromise();
            this._dialogService.open(this.errorDialog.dialogId);
        }

      } else {
          const keyToTranslate = 'Fill the required fields';
          this.errorDialogMessage = await this.translate.get(keyToTranslate).toPromise();
          this._dialogService.open(this.errorDialog.dialogId);
      }

    } else {
        // SE COMPARA EL CODIGO DE VALIDACION DE EMAIL Y SI ES CORRECTO SE EMITE PARA INVOCAR REACCION EN 'loginComponent'
        if ( this.codeEntered === this.validatorCode ) {
          ParamsService.addEditUserDialogEvent.emit(messageResult);

        }  else {
              ParamsService.addEditUserDialogEvent.emit( MessageResult.Cancel );  // SE CANCELA EL REGISTRO

              const keyToTranslate =  'The validation code is not correct';
              this.errorDialogMessage  = await this.translate.get( keyToTranslate).toPromise();
              this._dialogService.open(this.errorDialog.dialogId);
        }

    }
  }

  /**
   * Método que maneja el evento del boton Cancel del AddEdit
   */
  addEditButtonCancel(messageResult: MessageResult) {
    ParamsService.addEditUserDialogEvent.emit(messageResult);
  }

  /**
   * Inicializa el componente
   */
  ngOnInit() {
    this.classes.setValue(MessageStyle.Standard, 'bg-light');
    this.classes.setValue(MessageStyle.Information, 'bg-info text-center');
    this.classes.setValue(MessageStyle.Warning, 'bg-warning text-center');
    this.classes.setValue(MessageStyle.Error, 'bg-danger text-center');
    this.classes.setValue(MessageStyle.Success, 'bg-success text-center');

    if (this.operation !== 'Register') {
      this._userService.GetAdminsUsers((this.operation === 'Add' || this.operation === 'Register')  ? null : this.userDto._id).toPromise()
        .then((response) => {
          if (response) {
            // @ts-ignore
            this.dataSourceUsers = response.Admins;
            // console.log(this.dataSourceUsers)
          }
        })
        .catch((e) => {
          this.errorDialogMessage  = e.error.message ? e.error.message : e.message;
          this._dialogService.open(this.errorDialog.dialogId);
        });
    }

  }

  /**
   * Evento q se dispara al seleccionar el check box para cambiar el password del current user
   */
  public onValueChangedChangePassword(event) {
    // console.log(event.value);
    this.change_password_checkbox = event.value;
  }

  /**
   * Función que crea la expresión q mostrará el dropdown seleccion de usuarios
   */
  dropDownUsersDisplayExpr = (item) => {
    if (item) {
      return item['User'] + ' <' + item['Email'] + '>';
      // return item['User'] + ' (' + item['Description'] + ') <' + item['Email'] + '>';
    } else {
      return '';
    }
  };

}
