import {Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import { Router } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import * as CryptoJS from 'crypto-js';
import {Subscription} from 'rxjs';
import {UserDto} from '../../../dto-models/user-dto.model';
import {DialogService} from '../../../services/base/dialog.service';
import {ParamsService} from '../../../services/base/params.service';
import { AuthService } from '../../../services/services/auth.service';
import {UserService} from '../../../services/user.service';
import {AddEditChangeUserPasswordComponent} from '../../shared/components/add-edit-change-user-password/add-edit-change-user-password.component';
import {AddEditUserComponent} from '../../shared/components/add-edit-user/add-edit-user.component';
import {CustomDialogComponent} from '../../shared/components/custom-dialog/custom-dialog.component';
import {MessageResult, MessageStyle, MessageType} from '../../shared/helpers/global-enums';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit, OnDestroy {

  // CustomDialogComponent
  messageStyles = MessageStyle;
  messageTypes = MessageType;
  @ViewChild('SuccessDialog', {static: false}) successDialog: CustomDialogComponent;
  @ViewChild('ErrorDialog', {static: false})   errorDialog:   CustomDialogComponent;
  @ViewChild('WarningDialog', {static: true}) warningDialog: CustomDialogComponent; // static: true => usado dentro de onInit()
  successDialogMessage = '';
  errorDialogMessage = '';
  warningDialogMessage = '';

  // Referencia del contenedor para el componente dinamico addEditUserComponent
  @ViewChild('addEditUserComponentContainer', {static: false, read: ViewContainerRef }) addEditUserComponentContainer;

  // Representa el componente 'AddEditUserComponent' creado dinamicamete
  addEditUserComponent: any;

  // Referencia del contenedor para el componente dinamico addEditUserComponent
  @ViewChild('addEditChangeUserPasswordComponentContainer', {static: false, read: ViewContainerRef }) addEditChangeUserPasswordComponentContainer;

  // Representa el componente 'AddEditUserComponent' creado dinamicamete
  addEditChangeUserPasswordComponent: any;

  _subscriptions: Subscription[] = [];

  public  email: string;
  public  password: string;

  roles: string[];
  userDto: UserDto;
  operation: string;
  add_edit_title: string;

  constructor( private _authService: AuthService,
               private _userService: UserService,
               private _dialogService: DialogService,
               private _router: Router,
               private _componentFactoryResolver: ComponentFactoryResolver,
               private translate: TranslateService) {

    this.roles = ['INTERNAL_SELLER', 'BROKER', 'REFERRED'];

  }

  ngOnInit() {
     this.handleDialogEventSubscription();
     this.handleParamServiceSubscription();
  }

  /*
  *
  */
  private handleDialogEventSubscription() {
    // Standards Dialogs (shared/CustomDialogComponent) =======================================
    const messageResultEventSubscription = this.warningDialog.messageResultEvent.subscribe((messageResult: MessageResult) => {
      if (messageResult === MessageResult.Accept) {
        switch (this.operation) {
          case 'ForgotMyPassword':
            this._userService.SendValidatorCodeForChangePasswordEmail(this.email).toPromise()
              .then((response) => {
                if (response) {
                  // @ts-ignore
                  this.userDto = response.User;
                  ParamsService.validatorCode = (response as any).ValidatorCode;
                  // console.log(this.userDto );

                  this.add_edit_title = 'Cambio de Contraseña';
                  this.CreateChangeUserPasswordAddEditComponent();
                }
              })
              .catch(async (e) => {
                  // console.log(e);
                  // this.errorDialogMessage = e.error.message;
                  const keyToTranslate = e.error.message;
                  this.errorDialogMessage = await this.translate.get(keyToTranslate).toPromise() + ' < ' + this.email + ' >';
                  console.log(this.errorDialogMessage);
                  this._dialogService.open(this.errorDialog.dialogId);
              });
            break;
        } // switch
      } // if MessageResult.Accept
    });
    this._subscriptions.push(messageResultEventSubscription);
  } // handleDialogEventSubscription

  /*
  *
  */
  private handleParamServiceSubscription() {
    // subcripcion al evento emitido por los botones del dialogo  AddEditUserDialog =======================================
    const addEditUserDialogEventSubscription = ParamsService.addEditUserDialogEvent.subscribe(
      async (messageResult: MessageResult) => {
        if (messageResult === MessageResult.Accept) {

          switch ( this.operation ) {
            case 'Register':
              this._userService.RegisterUser(this.userDto).toPromise()
                .then((response) => {
                  if (response) {
                    // Mostrando cuadro de diálogo de creación de usuario y envío satisfactorio de mensaje con datos de registro
                    this.successDialogMessage = (response as any).message;
                    this._dialogService.open(this.successDialog.dialogId);

                    this.addEditUserComponent.instance.visible = false;
                  }
                })
                .catch(async (e) => {
                  const keyToTranslate = e.error.message;
                  this.errorDialogMessage = await this.translate.get(keyToTranslate).toPromise() + '< ' + this.userDto.Email + ' >';
                  this._dialogService.open(this.errorDialog.dialogId);
                });
              break;

          }// switch

        } else {
          // MessageResult.Cancel
          this.addEditUserComponent.instance.visible = false;
        }

      });
    this._subscriptions.push(addEditUserDialogEventSubscription);

    // subcripcion al evento emitido por los botones del dialogo  AddEditChangeUserPasswordDialog =======================================
    const addEditChangeUserPasswordDialogEventSubscription = ParamsService.addEditChangeUserPasswordDialogEvent.subscribe(
      async (messageResult: MessageResult) => {
        if (messageResult === MessageResult.Accept) {
          if (this.userDto.Password !== '') {
            if ( this.userDto.Password === this.userDto.ConfirmPassword ) {

              this._userService.ChangeUserPassword(this.userDto._id, {Password: this.userDto.Password}).toPromise()
                .then((response) => {
                  if (response) {
                    this.addEditChangeUserPasswordComponent.instance.visible = false;
                  }
                })
                .catch((e) => {
                  this.errorDialogMessage  = e.error.message ? e.error.message : e.message;
                  this._dialogService.open(this.errorDialog.dialogId);
                });

            } else {
              const keyToTranslate = 'The passwords not match';
              this.errorDialogMessage  = await this.translate.get( keyToTranslate).toPromise();
              this._dialogService.open(this.errorDialog.dialogId);
            }

          } else {
            this.errorDialogMessage  = 'Debe introducir una contraseña';
            this._dialogService.open(this.errorDialog.dialogId);
          }
        } else {
          // MessageResult.Cancel
          this.addEditChangeUserPasswordComponent.instance.visible = false;
        }

      });
    this._subscriptions.push(addEditChangeUserPasswordDialogEventSubscription);
  }

  onKeydown(event) {
    if (event.key === 'Enter') {
      this.Login();
    }
  }

  /*
* @method Register
* @description Método que implementa la lógica de registro de usuario*/
  Register() {
    this.userDto = new UserDto(
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      'BROKER',
      '',
      true,
      '',
      '',
      '');
    this.operation = 'Register';
    this.add_edit_title = 'User Register';
    this.CreateUserAddEditComponent();
  }

  private CreateUserAddEditComponent() {
    this.addEditUserComponentContainer.clear();
    const componentFactory  = this._componentFactoryResolver.resolveComponentFactory(AddEditUserComponent);
    this.addEditUserComponent = this.addEditUserComponentContainer.createComponent(componentFactory);

    this.addEditUserComponent.instance.is_logged_in_user = false;
    this.addEditUserComponent.instance.messageStyle      = this.messageStyles.Standard;
    this.addEditUserComponent.instance.userDto           = this.userDto;
    this.addEditUserComponent.instance.roles             = this.roles;
    this.addEditUserComponent.instance.title             = this.add_edit_title;
    this.addEditUserComponent.instance.operation         = this.operation;

    this.addEditUserComponent.instance.visible =  true;
  }

  private CreateChangeUserPasswordAddEditComponent() {
    this.addEditChangeUserPasswordComponentContainer.clear();
    const componentFactory  = this._componentFactoryResolver.resolveComponentFactory(AddEditChangeUserPasswordComponent);
    this.addEditChangeUserPasswordComponent = this.addEditChangeUserPasswordComponentContainer.createComponent(componentFactory);

    this.addEditChangeUserPasswordComponent.instance.messageStyle  = this.messageStyles.Standard;
    this.userDto.Email    = this.email;
    this.userDto.Password = this.password;
    this.addEditChangeUserPasswordComponent.instance.userDto       = this.userDto;
    this.addEditChangeUserPasswordComponent.instance.title         = this.add_edit_title;

    this.addEditChangeUserPasswordComponent.instance.visible =  true;
  }

  /*
  * @method Login
  * @description Método que implementa la lógica de login de usuario*/
  Login() {
    this._authService.login( this.email, this.password ).toPromise()
      .then((response) => {
        if (response) {
          const currentUser = JSON.stringify({
            // @ts-ignore
            user: response.user,
            // @ts-ignore
            token: response.token,
          });

          // Salvamos el 'currentUser' encriptado al localstore
          const encrypted = CryptoJS.AES.encrypt( currentUser, '**AfrontE01securYystems#09B*ackb*Kmoneystems#93**');
          localStorage.setItem('cu_key', encrypted.toString());

          // Navegamos home para cargar el fulllayout
          this._router.navigate(['/dashboard']);
        }
      })
      .catch(async (e) => {
        const error_message = e.error.message ? e.error.message : e.message;
        const keyToTranslate = error_message;
        this.errorDialogMessage = await this.translate.get(keyToTranslate).toPromise() + ' < ' + this.email + ' >';
        this._dialogService.open(this.errorDialog.dialogId);
      });
  }

  async ForgotMyPassword() {
    this.operation = 'ForgotMyPassword';
    const keyToTranslate = 'A message will be sent with a code to change your password. Do you want to proceed?';
    this.warningDialogMessage = await this.translate.get( keyToTranslate).toPromise();
    this._dialogService.open(this.warningDialog.dialogId);
  }

  ngOnDestroy() {
    this._subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
