export class CommissionConceptDto {
  public _id: string;
  public Code: string;
  public Display_Name: string;
  public Description: string;
  public Develop: string;
  public SaleValuePercent: number;
  public ReferredPercent: number;
  public Active_Referred: boolean;
  public Active: boolean;

  constructor(
    _id: string,
    Code: string,
    Display_Name: string,
    Description: string,
    Develop: string,
    SaleValuePercent: number,
    ReferredPercent: number,
    Active_Referred: boolean,
    Active: boolean) {

    this._id = _id;
    this.Code = Code;
    this.Display_Name = Display_Name;
    this.Description = Description;
    this.Develop = Develop;
    this.SaleValuePercent = SaleValuePercent;
    this.ReferredPercent = ReferredPercent;
    this.Active_Referred = Active_Referred;
    this.Active = Active;
  }
}
