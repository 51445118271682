import {Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {NotificationsService} from '../../../services/notifications.service';
import {AuthService} from '../../../services/services/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './full-layout.component.html',
})
export class FullLayoutComponent implements OnInit  {

  subscriptions: Subscription[] = [];

  /**
   *  Arreglo de notificaciones del usuario
   */
  notifications: any[] = [];
  messages: any[] = [];

  constructor(private _notificationsService: NotificationsService,
    private _authService: AuthService) {
    this.GetNotifications();
  }

  GetNotifications() {
    this._notificationsService.GetNotifications().toPromise()
      .then((response) => {
        // @ts-ignore
        this.notifications = response.Notifications;
        // console.log(this.notifications);
      })
      .catch((error) => {
        console.log(error.error.message);
      });

  }

  refreshNotifications(value) {
    // console.log('NIFICACION REFRESCADA');
    this.GetNotifications();
  }

  ngOnInit() {

    setInterval( () => {

      if (this._authService.isAutenticated()) {
        this.GetNotifications();
      }

    }, 60000); // cada 1m
  }

}
