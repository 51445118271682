import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

import {environment} from '../../../environments/environment';
import {UserService} from '../user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {

  constructor(private _router: Router,
              private _userService: UserService) { }

  /* Implementación del interceptor HTTP  (implements HttpInterceptor)
     que intercepta toda llamada al back-end para leer el código de estado de la respuesta y en caso de 401 o 403
     manda se llama al servicio 'Logout' para limpiar el localstorage redireccionar a la vista login:
      (401) => {message:'Error, non-authorized petition'}
      (403) => {message:'Error, the token is not valid or has expired'}
  * */
  intercept(req: HttpRequest<any>,
            next: HttpHandler): Observable<HttpEvent<any>> {

      const currentuser = this._userService.CurrentUser();

      if (currentuser) {
          const req_cloned = req.clone({
           headers: req.headers.append('token', currentuser.token),
          });

          return next.handle(req_cloned).pipe(tap(
            (success: any) => {
              /*
              * Si el usuaio 'previamente' almacenado en localstorage no es 'admin' y esta seteado el modo mantenimiento
              * redireccionamos a pagina de mantenimiento
              * */
              if ( environment.maintenance_mode && currentuser.user.User !== 'admin' ) {
                this._router.navigate(['/security/501']);
              }
            },
            (error: any) => {
                if (error instanceof HttpErrorResponse) {
                  if ( error.status === 403 || error.status === 401 ) {
                     // console.log(error.error.message); //Error, the token is not valid or has expired
                     this._userService.Logout();
                  }
                }
            },
          ));

      } else {
          return next.handle(req);
      }

  }
}
