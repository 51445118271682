import { Component, Input, OnInit } from '@angular/core';

// Import navigation elements
import { Router } from '@angular/router';
import { navigation } from '../../../_nav';
import { navigationDinamic } from '../../../_nav';
import { UserService } from '../../../services/user.service';
import { SystemService } from '../../../services/system.service';
import { DevelopsService } from '../../../services/develops.service';

///////////////////////////////////////////////////////////////////////////////////////////////////
@Component({
  selector: 'app-sidebar-nav',
  template: `
    <nav class="sidebar-nav">
      <ul class="nav">
        <ng-template ngFor let-navitem [ngForOf]="user_navigation">
          <li *ngIf="isDivider(navitem)" class="nav-divider"></li>

          <ng-template [ngIf]="isTitle(navitem)">
            <app-sidebar-nav-title [title]='navitem' translate></app-sidebar-nav-title>
          </ng-template>

          <ng-template [ngIf]="!isDivider(navitem) && !isTitle(navitem)">
            <app-sidebar-nav-item [item]='navitem' translate></app-sidebar-nav-item>
          </ng-template>

        </ng-template>
      </ul>
    </nav>`
})

export class AppSidebarNavComponent implements OnInit {

  develops: any;                  //---------------Arreglo de desarrollos
  // public user_navigation = navigation;
  public user_navigation: any[] = [];
  // Se obtiene el usuario logeado para consultar su 'role'
  currentUser: any;
  user_role: string;
  configFront: any;
  showNav = true;
  constructor(private _userService: UserService, private _systemService: SystemService,
    private _developsService: DevelopsService,
  ) {
    this.showNav = true;
  }

  ngOnInit() {
    this._developsService.GetDevelops().subscribe((data) => {
      this._systemService.Get_config_front().subscribe(config => {
        this.develops = data;
        this.configFront = config;
        this.InitDinamicMenu(navigationDinamic);
      });
    });
  }

  public isDivider(item) {
    return item.divider ? true : false
  }

  public isTitle(item) {
    if (item.title) {
      if (item.roles) {
        if (item.roles.find((role) => role === this.user_role)) { return true;}

      } else {
        return false;
      }

    } else {
      return false;
    }
  }

  public isCanShow(item) {
    const user_role = this.currentUser.user.Role;
    if (!item.show) {
      return true
    } else {
      return item.show === true && (user_role === 'SUPER_ADMIN' || user_role === 'ADMIN') ? true : false
    }
  }

  //inicia menu con datos estaticos de _nav.ts
  public initStaticMenu() {
    console.log('init static menu!!!');
    this.currentUser = this._userService.CurrentUser();

    if (this.currentUser) {
      this.user_role = this.currentUser.user.Role;
    } else {
      this.user_role = 'PUBLIC';
    }

    // Se asigna la constante 'navigation'
    this.user_navigation = navigation;

    // Se modifica la visibilidad de la variable 'user_navigation' que se toma para construir el nav
    for (const item of this.user_navigation) {
      item.visible = this.SetNavVisibility(item);
    }
  }

  public InitDinamicMenu(menuArray) {
    console.log('Iniciando menu dinamico');
    this.currentUser = this._userService.CurrentUser();
    let arrayTemp = [];
    this.user_navigation = [];
    if (this.currentUser) {
      this.user_role = this.currentUser.user.Role;
    } else {
      this.user_role = 'PUBLIC';
    }
   // console.log('develops', this.develops);
    //console.log('menuArray', this.configFront);
    menuArray.initialInfo.forEach(item => {
      arrayTemp.push(item);
    });
    this.develops.Develops.forEach(develop => {
      // if (develop.Active) {
      if (true) {
        arrayTemp.push(
          {
            name: develop.Name,
            icon: 'icon-layers',
            children: [
              {
                name: 'Departments',
                url: '/develops/palais/inventory/department/' + develop.Name + '/' + develop._id,
                roles: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR', 'BROKER', 'INTERNAL_SELLER', 'REFERRED'],
                visible: false,
                // icon: 'assets/img/RECURSOS/icono_DEPARTAMENTOS.png',
                // icon1: 'assets/img/RECURSOS/icono_DEPARTAMENTOS.png',
                icon: 'icon-home',
              },
              {
                name: 'Departments',
                // url: '/develops/palais/inventory/departments/' + develop.Name + '/' + develop._id,
                url: '/public/quote/department/' + develop.Name + '/' + develop._id,
                roles: ['PUBLIC'],
                visible: false,
                // icon: 'assets/img/RECURSOS/icono_DEPARTAMENTOS.png',
                // icon1: 'assets/img/RECURSOS/icono_DEPARTAMENTOS.png',
                icon: 'icon-home',
              },
              {
                name: 'Reservations',
                //url: '/develops/palais/inventory/reserved',
                url: '/develops/palais/inventory/reserved/' + develop.Name + '/' + develop._id,
                roles: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR', 'BROKER', 'INTERNAL_SELLER', 'REFERRED'],
                visible: false,
                icon: 'icon-notebook',
              },
              {
                name: 'Reservation Requests',
                url: '/develops/palais/inventory/reservation_requests/' + develop.Name + '/' + develop._id,
                roles: ['SUPER_ADMIN', 'ADMIN'],
                visible: false,
                icon: 'icon-paper-plane',
              },
            ],
          }

        );
      }
    });


    menuArray.mediumInfo.forEach(item => {
      arrayTemp.push(item);
    });


    this.configFront.EnlacesHerramientas.forEach(item => {
      console.log(item);
      arrayTemp.push(
        {
          name: item.descripcion,
          url: item.link,
          roles: ['SUPER_ADMIN', 'ADMIN', 'SUPERVISOR', 'INTERNAL_SELLER'],
          visible: false,
          icon: 'icon-cloud-download',
          variant: 'primary',
        });



    });


    this.user_navigation = arrayTemp;
    for (const item of this.user_navigation) {
      item.visible = this.SetNavVisibility(item);
    }
    // console.log( this.user_navigation );

  }

  SetNavVisibility(item: any) {
    let parent_visible = false;

    if (item.children !== undefined) {
      for (const child of item.children) {
        if (this.SetNavVisibility(child)) {
          parent_visible = true;
        }
      }
      item.visible = parent_visible;
    }

    if (item.roles !== undefined) {

      if (item.roles.find((role) => role === this.user_role)) {
        item.visible = true;
        return true;
      }
    }
    return parent_visible;
  }
}

@Component({
  selector: 'app-sidebar-nav-item',
  /*<app-sidebar-nav-link     => Representa las opciones "links" que lanzan las vistas CRUD
    llamada recursiva a <app-sidebar-nav-dropdown  la cual contiene llamada a <app-sidebar-nav-link*/
  template: `
    <li *ngIf="!isDropdown() && isVisible(item); else dropdown" [ngClass]="hasClass() ? 'nav-item ' + item.class : 'nav-item'">
       <app-sidebar-nav-link [link]='item' translate></app-sidebar-nav-link>
    </li>

    <ng-template #dropdown>
      <li *ngIf='isVisible(item)' [ngClass]="hasClass() ? 'nav-item nav-dropdown ' + item.class : 'nav-item nav-dropdown'"
          [class.open]="isActive()"
          routerLinkActive="open"
          appNavDropdown translate>
        <app-sidebar-nav-dropdown [link]='item' translate></app-sidebar-nav-dropdown>
      </li>           <!--[class.open]="isActive()"-->
    </ng-template>
    `
})
export class AppSidebarNavItemComponent {

  @Input() item: any;

  constructor(private router: Router) { }

  public isVisible(item) {
    return item.visible ? true : false
  }

  public hasClass() {
    return this.item.class ? true : false
  }

  public isDropdown() {
    return this.item.children ? true : false
  }

  public thisUrl() {
    return this.item.url
  }

  public isActive() {
    return this.router.isActive(this.thisUrl(), false)  //dropdown desarrollos true para desplegar
  }

}

@Component({
  selector: 'app-sidebar-nav-link',
  template: `
    <div *ngIf="!isExternalLink(); else external" >
      <a *ngIf="isVisible(link)"
        [ngClass]="hasVariant() ? 'nav-link nav-link-' + link.variant : 'nav-link'"
        routerLinkActive="active"
        [routerLink]="[link.url]"
        (click)="hideMobile()" (mouseleave)="ImgBlackWhite(true)" (mouseover)="ImgBlackWhite(false)">

        <i *ngIf="isIcon()" class="{{ link.icon }}"></i>
        <!--<img *ngIf="isIcon()" [src]="this.change ? link.icon : link.icon1" style="float:left;" hspace="15" >-->
        <a class="sidebar-nav-link">{{ link.name | translate}}</a>

        <span *ngIf="isBadge()" [ngClass]="'badge badge-' + link.badge.variant">{{ link.badge.text }}</span>
      </a>
    </div>
    <ng-template #external>
      <a [ngClass]="hasVariant() ? 'nav-link nav-link-' + link.variant : 'nav-link'" href="{{link.url}}" target="_blank">
        <i *ngIf="isIcon()" class="{{ link.icon }}"></i>
        <a class="sidebar-nav-link external-link">{{ link.name }}</a>
        <span *ngIf="isBadge()" [ngClass]="'badge badge-' + link.badge.variant">{{ link.badge.text }}</span>
      </a>
    </ng-template>
  `
})
export class AppSidebarNavLinkComponent {
  @Input() link: any;
  change = true;

  constructor() { }

  public isVisible(item) {
    return item.visible ? true : false
  }

  public ImgBlackWhite(value) {
    return this.change = value ? true : false;
  }


  public hasVariant() {
    return this.link.variant ? true : false
  }

  public isBadge() {
    return this.link.badge ? true : false
  }

  public isExternalLink() {
    return this.link.url.substring(0, 4) === 'http' ? true : false
  }

  public isIcon() {
    return this.link.icon ? true : false
  }

  public hideMobile() {
    // if (document.body.classList.contains('sidebar-mobile-show')) {
    //   document.body.classList.toggle('sidebar-mobile-show')
    // }
  }
}

@Component({
  selector: 'app-sidebar-nav-dropdown',
  template: `
    <a [ngClass]="hasVariant() ? 'nav-link nav-dropdown-toggle nav-link-' + link.variant : 'nav-link nav-dropdown-toggle'"
      appNavDropdownToggle>

      <i *ngIf="isIcon()" class="{{ link.icon }}"></i>

      <a class="sidebar-nav-dropdown">{{ link.name | translate }}</a>

      <span *ngIf="isBadge()" [ngClass]="'badge badge-' + link.badge.variant">{{ link.badge.text }}</span>
    </a>

    <ul class="nav-dropdown-items">
      <ng-template ngFor let-child [ngForOf]="link.children">
        <app-sidebar-nav-item [item]='child'></app-sidebar-nav-item>
      </ng-template>
    </ul>
  `
})
export class AppSidebarNavDropdownComponent {
  @Input() link: any;

  public isBadge() {
    return this.link.badge ? true : false
  }

  public isIcon() {
    return this.link.icon ? true : false
  }

  public hasVariant() {
    return this.link.variant ? true : false
  }

  constructor() { }
}

@Component({
  selector: 'app-sidebar-nav-title',
  templateUrl: './app.sidebar-nav-title.html',
})
export class AppSidebarNavTitleComponent implements OnInit {
  @Input() title: any;
  class = 'nav-title';

  constructor() { }

  ngOnInit() {
  }
}

export const APP_SIDEBAR_NAV = [
  AppSidebarNavComponent,
  AppSidebarNavDropdownComponent,
  AppSidebarNavItemComponent,
  AppSidebarNavLinkComponent,
  AppSidebarNavTitleComponent
];
