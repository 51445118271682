import { Component } from '@angular/core';

@Component({
  templateUrl: '501.component.html',
})
export class P501Component {

  constructor() { }

}
