import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';

import {DxDataGridComponent} from 'devextreme-angular';
import {Subscription} from 'rxjs';
import * as Collections from 'typescript-collections';
import {CommissionConceptDto} from '../../../../dto-models/commission-concept-dto.model';
import {ParamsService} from '../../../../services/base/params.service';
import {CommissionsConceptsService} from '../../../../services/commissions-concepts.service';
import {MessageResult, MessageStyle} from '../../helpers/global-enums';

@Component({
  selector: 'app-assignation-commission-concept',
  templateUrl: './assignation-commission-concept.component.html',
})
export class AssignationCommissionConceptComponent implements OnInit, OnDestroy {

  /**
   * Contiene una referencia al componente dx-data-grid de DevExpress
   */
  @ViewChild('gridAssignationCommissionConcepts', {static: false}) gridAssignationCommissionConcepts: DxDataGridComponent;

  /**
   * Controla la visibilidad del poppup
   */
  @Input() visible: boolean;

  /**
   * Contiene el título del mensaje a mostrar
   */
  @Input() title = '';

  /**
   * Contiene el estilo del mensaje a mostrar
   * @type {MessageStyle}
   */
  @Input() messageStyle: MessageStyle;

  /**
   * Contiene el tipo del mensaje a mostrar
   * @type {string}
   */
  @Input() param: string;

  /**
   * CommissionConceptDto para creacion de registro de relacion 'User_CommissionConceptDto' segun su Id
   */
  @Input() commissionConceptDto: CommissionConceptDto;

  @Output() messageResultEvent = new EventEmitter<MessageResult>();

  /**
   * Contiene las posibles clases a aplicar al mensaje en dependencia del {@link messageStyles}
   * @type {Dictionary<MessageStyle, string>}
   */
  classes = new Collections.Dictionary<MessageStyle, string>();

  messageStyles = MessageStyle;
  messageResults = MessageResult;
  isOpened = false;

  /**
   * Controla todas las suscripciones a Observables
   */
  subscriptions: Subscription[] = [];

  commissionConcepts_dataSource: CommissionConceptDto[];
  selectedCommissionConcept: CommissionConceptDto;

  constructor(private _commissionsConceptsService: CommissionsConceptsService) {
  }

  GetCommissionConcepts() {
    const commissionsConceptsServiceGetCommissionConcepts = this._commissionsConceptsService.GetCommissionConcepts(null).subscribe(
      (response) => {
        if (response) {
          // @ts-ignore
          this.commissionConcepts_dataSource = response.CommissionConceptList;

          // @ts-ignore
          // console.log(this.dataSource)
        }
      },
      (error) => {
        console.log(error.error.message);
      });
    this.subscriptions.push(commissionsConceptsServiceGetCommissionConcepts);
  }

  /**
   * Evento q se dispara al seleccionar una Department del grid
   */
  public handleSelectionChangedEvent(event) {
    this.selectedCommissionConcept = event.selectedRowsData[0];
    // console.log(this.selectedCommissionConcept);

    this.commissionConceptDto._id               = this.selectedCommissionConcept._id;
    this.commissionConceptDto.Code              = this.selectedCommissionConcept.Code;
    this.commissionConceptDto.Display_Name      = this.selectedCommissionConcept.Display_Name;
    this.commissionConceptDto.Description       = this.selectedCommissionConcept.Description;
    this.commissionConceptDto.Develop           = this.selectedCommissionConcept.Develop;
    this.commissionConceptDto.SaleValuePercent  = this.selectedCommissionConcept.SaleValuePercent;
    this.commissionConceptDto.Active            = this.selectedCommissionConcept.Active;

    // console.log(this.commissionConceptDto);
  }

  /**
   * Método que maneja el evento del boton Accept del AddEdit
   */
  buttonAccept(messageResult: MessageResult) {
    // if ( event.validationGroup.validate().isValid ) {
      ParamsService.assignationCommissionConceptComponentEvent.emit(messageResult);
    // }
  }

  /**
   * Método que maneja el evento del boton Cancel del AddEdit
   */
  buttonCancel(messageResult: MessageResult) {
    ParamsService.assignationCommissionConceptComponentEvent.emit(messageResult);
  }

  /**
   * Inicializa el componente
   */
  ngOnInit() {
    this.GetCommissionConcepts();

    this.classes.setValue(MessageStyle.Standard, 'bg-light');
    this.classes.setValue(MessageStyle.Information, 'bg-info text-center');
    this.classes.setValue(MessageStyle.Warning, 'bg-warning text-center');
    this.classes.setValue(MessageStyle.Error, 'bg-danger text-center');
    this.classes.setValue(MessageStyle.Success, 'bg-success text-center');
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
