<dx-popup
  [position]="{my: 'center', at: 'center', of: '.main', offset: '0 50'}"
  [showTitle]="false"
  [showCloseButton]="false"
  [(visible)]="visible">

  <div *dxTemplate="let data of 'content'">
    <!-- 'both' | 'horizontal' | 'vertical' -->
    <dx-scroll-view
      [useNative]="false"
      [scrollByContent]="true"
      [scrollByThumb]="true"
      direction="vertical">
      <div class="card popup-card" [ngClass]="classes.getValue(messageStyle)">
        <div
          class="card-header-modal font-weight-bold">
          {{title | translate}}
        </div>

        <div class="card-body">
          <div class="card">
            <div class="card-body">

              <div class="col-sm-5">
                <img class="img-title" src="assets/img/RECURSOS/customers.png" alt="customers.png"/>
                <h4 class="checkbox-avenir-title mb-0">{{'Customers' | translate}}</h4>
                <br>
              </div>

              <dx-data-grid
              #gridCustomers
              [dataSource]="customers_dataSource"
              [rowAlternationEnabled]="false"
              [columnAutoWidth]="true"
              [cacheEnabled]="false"
              (onCellPrepared)="handleCellPrepared($event)"
              (onSelectionChanged)="handleSelectionChangedEvent($event)">

              <dxo-paging
                [pageSize]="5">
              </dxo-paging>

              <dxo-pager
                [showPageSizeSelector]="true"
                [allowedPageSizes]="[5,10,20]"
                [showInfo]="true">
              </dxo-pager>

              <dxo-scrolling
                showScrollbar="always">
              </dxo-scrolling>

              <dxo-selection
                [mode]="'single'"
                selectAllMode="page"
                showCheckBoxesMode="none">
              </dxo-selection>

              <dxo-search-panel
                [visible]="true"
                plaseholder="Search...">
              </dxo-search-panel>

                <dxi-column cssClass="dx-data-grid-avenir" dataField = "Customer" [caption]="'Customer' | translate" [alignment]="'center'"></dxi-column>
                <dxi-column cssClass="dx-data-grid-avenir" dataField = "Description" [caption]="'Name & Surname' | translate" [alignment]="'center'"></dxi-column>
                <dxi-column cssClass="dx-data-grid-avenir" dataField = "Email" [alignment]="'center'"></dxi-column>
                <!--<dxi-column dataField = "Phone" [alignment]="'center'"></dxi-column>-->
                <dxi-column cssClass="dx-data-grid-avenir" dataField = "User.User"  [caption]="'Owner' | translate" [alignment]="'center'"></dxi-column>
                <dxi-column cssClass="dx-data-grid-avenir" dataField = "Referred_User.User" [caption]="'Referred' | translate" [alignment]="'center'"></dxi-column>
                <dxi-column cssClass="dx-data-grid-avenir" dataField = "Country" [caption]="'Country' | translate" [alignment]="'center'"></dxi-column>
                <!--<dxi-column dataField = "User.Role" [caption]="'Role'" [alignment]="'center'"></dxi-column>-->
                <!--<dxi-column dataField = "DepartmentCode" [caption]="'Department'" [alignment]="'center'"></dxi-column>-->
                <!--<dxi-column dataField = "AdmissionDate" dataType="date" format="shortDateShortTime" [alignment]="'center'"></dxi-column>-->
                <!--<dxi-column dataField = "PaymentFormId" [alignment]="'center'"></dxi-column>-->

            </dx-data-grid>
            </div>
          </div>

        </div>

        <div class="card-footer-modal">
          <div class="pull-right">

            <dx-button class="btn-avenir-celest-blue"
              [disabled]="selectedCustomer == null"
              [text]="'Accept' | translate"
              (onClick)="buttonAccept(messageResults.Accept)"
              [useSubmitBehavior]="true">
            </dx-button>

            <dx-button class="dlg-button-margin btn-avenir-red"
              type="primary"
              [text]="'Cancel' | translate"
              (onClick)="buttonCancel(messageResults.Cancel)">
            </dx-button>
            &nbsp;
          </div>
        </div>
      </div>
    </dx-scroll-view>
  </div>

</dx-popup>
