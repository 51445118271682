export class CustomerDto {
  public _id: string;
  public Customer: string;
  public Description: string;
  public Phone: string;
  public Email: string;
  public DNI: string;
  public Passport: string;
  public Address: string;
  public Country: string;
  public AdmissionDate: Date;
  public User: string;
  public Referred_User: string;
  public Active_Referred: boolean;

  constructor(
    _id: string,
    Customer: string,
    Description: string,
    Phone: string,
    Email: string,
    DNI: string,
    Passport: string,
    Address: string,
    Country: string,
    AdmissionDate: Date,
    User: string,
    Referred_User: string,
    Active_Referred: boolean) {
      this._id = _id;
      this.Customer = Customer;
      this.Description = Description;
      this.Phone = Phone;
      this.Email = Email;
      this.DNI = DNI;
      this.Passport = Passport;
      this.Address = Address;
      this.Country = Country;
      this.AdmissionDate = AdmissionDate;
      this.User = User;
      this.Referred_User = Referred_User;
      this.Active_Referred = Active_Referred;
  }
}
