<div class="app flex-row align-items-center"
  style="background-image: url('assets/img/loggin_background.jpg'); background-repeat: no-repeat; background-size: cover;">
  <div class="container" style="width: 800px">
    <div class="row justify-content-center">
    <img style="width:50%; height: 50%" src="assets/img/logo-gold.png" />

    </div>
    <!-- <br> -->
    <div class="row justify-content-center">
      <div class="col-md-8">
        <div class="card-group ">
          <div class="card p-4 bg-loggin-custom">


            <div class="card-body">
              <!--<img style="width:90%" src="assets/img/logo.png"/>-->
              <!--<br><br>-->
              <!--<h2>Login</h2>-->

              <!-- <div class="row justify-content-center">
                  <img style="width:95%; height: 75%; margin-top:-3.5rem;" src="assets/img/logo-gold_5.png" />
                </div> -->


              <div class="row mb-4">
                <p class="text-muted-loggin justify-content-center custom-center-login">
                  {{'Welcome sign in to your account' | translate}}</p>
              </div>


              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="icon-user"></i></span>
                </div>
                <input type="text" [(ngModel)]="email" class="form-control" placeholder="Email">
              </div>
              <div class="input-group mb-4">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="icon-lock"></i></span>
                </div>
                <input type="password" [(ngModel)]="password" (keydown)="onKeydown($event)" class="form-control"
                  placeholder="Password">
              </div>

              <div class="row mt-2">
              <button type="button" class="btn px-4 init-button custom-center-login" style="width: 140px"
              (click)="Login()">{{ 'Login' | translate }}</button>
              </div>
<br>
              <div class="row mt-2">
                  <a class="nav-link header-language-loggin custom-center-login"
                    (click)="ForgotMyPassword()">{{ 'Did you forget your password?' | translate }}</a>
                  </div>


              <!-- <div class="row mt-2">

                <div class="col-3"></div>
                <div class="col-9">
                   <button type="button" class="btn register-button float-right" style="width: 140px" (click)="Register()" >{{ 'Register'  | translate }} </button>

                  <button type="button" class="btn px-4 init-button" style="width: 140px; margin-left: 1.0rem;"
                    (click)="Login()">{{ 'Login' | translate }}</button>
                </div>
              </div> -->

              <!-- <div class="row">
                <div class="col-2"></div>
                <div class="col-8">
                  <br>
                  <a class="nav-link header-language-loggin" style="margin-left: 1.3rem;"
                    (click)="ForgotMyPassword()">{{ 'Did you forget your password?' | translate }}</a>
                </div>
              </div> -->

              <!-- <br>
              <div class="row">
                <div class="text-muted-loggin">{{'Powered by' | translate}} <a class="link-loggin" href="http://www.seteados.com" target="_blank">Seteados</a></div>
              </div> -->

              <!--<script src="//code.jivosite.com/widget/MEOITF257B" async></script>-->

            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<app-custom-dialog #SuccessDialog [position]="{ my: 'center', at: 'center', of: 'window' }"
  [messageStyle]="messageStyles.Success" [messageType]="messageTypes.Default" [title]="'Success'"
  [message]="successDialogMessage">
</app-custom-dialog>

<app-custom-dialog #ErrorDialog [position]="{ my: 'center', at: 'center', of: 'window' }"
  [messageStyle]="messageStyles.Error" [messageType]="messageTypes.Default" [title]="'Error'"
  [message]="errorDialogMessage">
</app-custom-dialog>

<app-custom-dialog #WarningDialog [position]="{ my: 'center', at: 'center', of: 'window' }"
  [messageStyle]="messageStyles.Warning" [messageType]="messageTypes.Confirmation" [title]="'Warning'"
  [message]="warningDialogMessage">
</app-custom-dialog>

<template #addEditUserComponentContainer></template>

<template #addEditChangeUserPasswordComponentContainer></template>
