import {
  Component,
  ComponentFactoryResolver,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DxDataGridComponent, DxToastComponent} from 'devextreme-angular';
import {Subscription} from 'rxjs';
import {AlternativeDiscountDto} from '../../../dto-models/alternative-discount-dto.model';
import {DevelopDto} from '../../../dto-models/develop-dto.model';
import {DiscountDto} from '../../../dto-models/discount-dto.model';
import {DialogService} from '../../../services/base/dialog.service';
import {ParamsService} from '../../../services/base/params.service';
import {DevelopsService} from '../../../services/develops.service';
import {DiscountsService} from '../../../services/discounts.service';
import {UserService} from '../../../services/user.service';
import {AddEditDiscountsComponent} from '../../shared/components/add-edit-discounts/add-edit-discounts';
import {CustomDialogComponent} from '../../shared/components/custom-dialog/custom-dialog.component';
import {MessageResult, MessageStyle, MessageType} from '../../shared/helpers/global-enums';
import { Router } from '@angular/router';

@Component({
  selector: 'app-discounts',
  templateUrl: './discounts.component.html',
})
export class DiscountsComponent implements OnInit, OnDestroy {

  messageStyles = MessageStyle;
  messageTypes = MessageType;
  // CustomDialogComponent
  @ViewChild('SuccessDialog', {static: false}) successDialog: CustomDialogComponent;
  @ViewChild('WarningDialog', {static: true}) warningDialog: CustomDialogComponent; // static: true => usado dentro de onInit()
  @ViewChild('ErrorDialog', {static: false})   errorDialog:   CustomDialogComponent;
  successDialogMessage = '';
  warningDialogMessage = '';
  errorDialogMessage = '';

  @ViewChild('successToast', {static: false}) successToast: DxToastComponent;

  /**
   * Contiene una referencia al componente dx-data-grid de DevExpress
   */
  @ViewChild('gridDiscounts_downPayments', {static: false}) gridDiscounts_downPayments: DxDataGridComponent;
  @ViewChild('gridDiscounts_quotes', {static: false}) gridDiscounts_quotes: DxDataGridComponent;

  /**
   * Referencia al contenedor del componente 'addEditCommissionsConceptsComponentContainer' paara implementar creación dinámica
   */
  @ViewChild('addEditDiscountComponentContainer', {static: false, read: ViewContainerRef }) addEditDiscountComponentContainer;
  // Representa el componente 'AddEditDiscountComponent' creado dinámicamente
  addEditDiscountComponent: any;

  /* Controla todas las suscripciones a Observables   */
  subscriptions: Subscription[] = [];

  develops: any[];             // arreglo de desarrollos
  currentDevelop: DevelopDto;  // Desarrollo activo

  alternativeDiscountDto: AlternativeDiscountDto;
  alternativesDiscountsList: any[] = [];
  selectedAlternativeDiscount: AlternativeDiscountDto;
  newAlternativeDiscount: AlternativeDiscountDto;
  generalDisountTableSelected = true;
  downPayments_discounts_dataSource: any[] = [];
  quotes_discounts_dataSource: any[] = [];

  operation: string;
  add_edit_title: string;

  currentUser: any;
  public roleAdminUser: boolean;                      // Usuario Administrador
  public editAlternativeDiscountTable = false;        // Muestra los campos para creacion de AlternativeDiscountTable

  selectedDiscount: any;                              // viene populate con Develop ...
  discountDto: DiscountDto;

  minSumDownPaymentQuotesToApplyDiscount_General: number;
  minSumDownPaymentQuotesToApplyDiscount_DiscountTableSelected: number;

  constructor( private _userService: UserService,
               private _dialogService: DialogService,
               private _developsService: DevelopsService,
               private _discountsService: DiscountsService,
               private _componentFactoryResolver: ComponentFactoryResolver,
               private _router: Router,
               private translate: TranslateService
               ) {

    // CurrentUser logeado
    if ( !ParamsService.publicQuote ) {
      this.currentUser   = this._userService.CurrentUser();
      this.roleAdminUser = this.currentUser.user.Role === 'SUPER_ADMIN' || this.currentUser.user.Role === 'ADMIN';
    } else {
        this.roleAdminUser = false;
    }

    this.selectedAlternativeDiscount = new AlternativeDiscountDto(
      '0',
      null,
      'General',
      'General',
      0,
    );

    this.GetDevelops();
  }

  /**
   * @method: alternativesDiscountsList_SelectionChanged
   * @description: Reacciona ante cambio de selección de tabla de descuento
   */
  alternativesDiscountsList_SelectionChanged( event ) {
    // SE OBTIENE 'id' DE TABLA ALTERNATIVA SELECCIONADO
    const selectedAlternativeDiscountId = event.value;

    // SE OBTIENE LA TABLA DE DE DESCUENTO ALTERNATIVA
    this.selectedAlternativeDiscount = this.alternativesDiscountsList.find((item) => item._id === selectedAlternativeDiscountId );

    // SE INICIALIZA EL DTO A ESTE OBJETO
    this.alternativeDiscountDto = this.selectedAlternativeDiscount;

    this.editAlternativeDiscountTable =  false;
    let param;
    if ( selectedAlternativeDiscountId === '0' ) {

      // SE INDICA QUE EL ELEMENTO SELECCIONADO ES EL FAKE 'General'
      this.generalDisountTableSelected = true;

      param = {
        AlternativeDiscountId: '',
      };

    } else {
        // SE INDICA QUE EL ELEMENTO SELECCIONADO ES UNA ''REAL' TABLA DE DESCUENTO
        this.generalDisountTableSelected = false;

        // EN ESTE CASO SE OBTIENE EL VALOR DE MIN PARA APPLICAR DESCUENTO DE LA CORRESPONDIENTE TABLA
    this.minSumDownPaymentQuotesToApplyDiscount_DiscountTableSelected = this.selectedAlternativeDiscount.MinSumDownPayment_QuotesToApplyDiscount;

        param = {
          AlternativeDiscountId: selectedAlternativeDiscountId,
        };
    }

    // SE OBTIENEN LAS TABLAS DE DESCTUENTO ENGANCHE Y MENSUALIDADES PARA LA TABLA SELECCIONADA
    this.GetDiscountsTables(this.currentDevelop._id, param);
  }

  /**
   * Refresca el grid de descuentos
   */
  private RefreshGrid() {
    let param;
    if ( this.selectedAlternativeDiscount._id === '0' ) {
      param = {
        AlternativeDiscountId: '',
      };
    } else {
      param = {
        AlternativeDiscountId: this.selectedAlternativeDiscount._id,
      };
    }
    this.GetDiscountsTables(this.currentDevelop._id, param);
  }

  /**
   * Obtención de Desarrollos
   */
  GetDevelops() {
    this._developsService.GetDevelops().toPromise()
      .then((response) => {
        if (response) {
          // @ts-ignore
          this.develops = response.Develops;

          // Se obtiene el desarrollo activo ('Palais Tulum')
        //  this.currentDevelop = this.develops.find((i) => i.Code === 'D-01');
         // Se obtiene el 1er desarrollo de la lista
          this.currentDevelop = this.develops.find((i) => i._id.length > 0);

          // SE OBTIENE EL VALOR DE MIN PARA APPLICAR DESCUENTO "GENERAL PARA EL DESARROLLO"
          this.minSumDownPaymentQuotesToApplyDiscount_General = this.currentDevelop.MinSumDownPayment_QuotesToApplyDiscount;

          // SE OBTIENEN LAS TABLAS DE DESCTUENTO ENGANCHE Y MENSUALIDADES GENERAL DEL DESARROLLO
          const param = {
            AlternativeDiscountId: '',
          };
          this.GetDiscountsTables(this.currentDevelop._id, param);

          this.GetAlternativesDiscountsList();
        }
      })
      .catch((e) => {
        this.errorDialogMessage  = e.error.message ? e.error.message : e.message;
        this._dialogService.open(this.errorDialog.dialogId);
      });
  }

  /**
   * @method: GetAlternativesDiscountsList
   * @description: Obtencion del listado de descuentos alternativos
   */
  GetAlternativesDiscountsList() {
    this._discountsService.GetAlternativesDiscountsList( this.currentDevelop._id ).toPromise()
      .then((response) => {
        if (response) {

          // SE OBTIENE LA TABLA DE DESCUENTOS ALTERNATIVOS
          this.alternativesDiscountsList = (response as any).AlternativesDiscountsList;

          // SE ADICIONA AL INICIO DE LA LISTA UN ELEMENTO 'FAKE' PARA TOMAR ACCTION ENTRE COMPORTAMIENTO DE DESCUENTO GENERAL O POR TABLA DE DESCUENTO ESPECIFICA
          this.alternativesDiscountsList.splice(0, 0,
            {
              _id: '0',
              Develop: this.currentDevelop._id,
              Name: 'General',
              Description: 'General',
            });

          if ( this.newAlternativeDiscount ) {
            this.selectedAlternativeDiscount = this.newAlternativeDiscount;

          } else {
              if ( ParamsService.AlternativeDiscount ) {
                this.selectedAlternativeDiscount = ParamsService.AlternativeDiscount;
              } else {
                this.selectedAlternativeDiscount = this.alternativesDiscountsList[0];
              }
          }

        }
      })
      .catch((e) => {
        this.errorDialogMessage  = e.error.message ? e.error.message : e.message;
        this._dialogService.open(this.errorDialog.dialogId);
      });
  }

  /**
   * @method: GetDiscountsTables
   * @description: Obtencion de las tablas de descuentos por Enganche y Mensualidades de un desarrollo ( POR DEFECTO)
   */
  GetDiscountsTables( developId, param) {
    this._discountsService.GetDiscountsTablesByDevelop( developId, param ).toPromise()
      .then((response) => {
        if (response) {
          // @ts-ignore
          const discounts = response.Discounts;

          if ( discounts.length > 0 ) {
            this.downPayments_discounts_dataSource = discounts.filter((d) => d.Type === 'down_payment');
            this.gridDiscounts_downPayments.instance.refresh();

            this.quotes_discounts_dataSource       = discounts.filter((d) => d.Type === 'quotes');
            this.gridDiscounts_quotes.instance.refresh();
          }
        }
      })
      .catch((e) => {
        this.errorDialogMessage  = e.error.message ? e.error.message : e.message;
        this._dialogService.open(this.errorDialog.dialogId);
      });
  }

  ngOnInit() {
    this.handleDialogEventSubscription();
    this.handleParamServiceSubscription();
  }

  private handleParamServiceSubscription() {
    // subcripcion al evento emitido por los botones del dialogo 'addEditDiscountComponent'
    const addEditDiscountComponentEventEventSubscription = ParamsService.addEditDiscountComponentEvent.subscribe(async (messageResult: MessageResult) => {
      if (messageResult === MessageResult.Accept) {
        switch (this.operation) {
          case 'Update':
            // console.log( this.discountDto );
            if ( this.discountDto.Develop !== '' ) {
              this._discountsService.UpdateDiscount(this.selectedDiscount._id, this.discountDto).toPromise()
                .then((response) => {
                  if (response) {
                    this.RefreshGrid();
                    this.addEditDiscountComponent.instance.visible = false;  // Se cierra el cuadro de dialogo 'addEditDiscountComponent'
                  }
                })
                .catch((e) => {
                  this.errorDialogMessage  = e.error.message ? e.error.message : e.message;
                  this._dialogService.open(this.errorDialog.dialogId);
                });

            } else {
                const keyToTranslate = 'Fill the required fields';
                this.errorDialogMessage  = await this.translate.get( keyToTranslate).toPromise();
                this._dialogService.open(this.errorDialog.dialogId);
            }
            break;
        }// switch

      } else {
        // MessageResult.Cancel se refresca el grid por si se edito algún cambio q no se refleje en el grid
        this.addEditDiscountComponent.instance.visible =  false;  // Se cierra el cuadro de dialogo 'addEditCustomersComponent'
        this.RefreshGrid();
      }

    });
    this.subscriptions.push(addEditDiscountComponentEventEventSubscription);
  }

  /**
   * @method: onDevelopSelectionChanged
   * @description Gestiona el 'Id' del desarrollo selecionado
   */
  onDevelopSelectionChanged(event) {
    const selectedDevelopId = event.value;

    if ( selectedDevelopId ) {
      // Se obtiene el desarrollo activo según selección
      this.currentDevelop = this.develops.find((i) => i._id === selectedDevelopId);
      this.minSumDownPaymentQuotesToApplyDiscount_General = this.currentDevelop.MinSumDownPayment_QuotesToApplyDiscount;

      // SE INICIALIZA LA TABLA DE DESCUENTO POR DEFECTO "General"
      this.selectedAlternativeDiscount = this.alternativesDiscountsList[0];
      this.GetAlternativesDiscountsList();
    }
  }

  handleCellPrepared(e) {
    if (e.rowType === 'header') {
      e.cellElement.className = 'dx-data-grid-avenir-title';
    }
  }

  /*
   * Gestion de seleccion de registros del grid de descuentos
   */
  handleGridSelectionChangedEvent(event) {
    this.selectedDiscount = event.selectedRowsData[0];
    // console.log(this.selectedDiscount);
  }

  /*
  * Gestion de seleccion de registros del grid de descuentos 'programaticamente'
  */
  SelectDiscountDownPaymentsRow(data) {
    this.gridDiscounts_downPayments.instance.selectRowsByIndexes([data.rowIndex]);
    // this.selectedDiscount = data.data;
  }
  SelectDiscountQuotesRow(data) {
    this.gridDiscounts_quotes.instance.selectRowsByIndexes([data.rowIndex]);
    // this.selectedDiscount = data.data;
  }

  /*
  *
  */
  private handleDialogEventSubscription() {
    // Standards Dialogs (shared/CustomDialogComponent) =======================================
    const messageResultEventSubscription = this.warningDialog.messageResultEvent.subscribe((messageResult: MessageResult) => {
      if (messageResult === MessageResult.Accept) {
        switch ( this.operation ) {
          case 'AddAlternativeDiscountTable':
            if ( this.alternativeDiscountDto ) {
              const params = {
                AlternativeDiscountName       : this.alternativeDiscountDto.Name,
                AlternativeDiscountDescription: this.alternativeDiscountDto.Description,
              };
              this._discountsService.AddAlternativeDiscountsTables(this.currentDevelop._id, params).toPromise()
                .then((response) => {
                  if (response) {
                    this.newAlternativeDiscount = (response as any).AlternativeDiscount;
                    this.redirectTo('/core/discounts');
                    // console.log(this.newAlternativeDiscount);

                   // this.GetAlternativesDiscountsList();
                  }
                })
                .catch(async (e) => {
                  const error_message = e.error.message ? e.error.message : e.message;
                  const keyToTranslate = error_message;
                  this.errorDialogMessage = await this.translate.get(keyToTranslate).toPromise();
                  this._dialogService.open(this.errorDialog.dialogId);
                });
            }
            break;

          case 'RemoveAlternativeDiscountTable':
            this._discountsService.RemoveAlternativeDiscountsTables(this.selectedAlternativeDiscount._id).toPromise()
              .then((response) => {
                if (response) {
                  this.selectedAlternativeDiscount = this.alternativesDiscountsList[0];
                  this.GetAlternativesDiscountsList();
                }
              })
              .catch(async (e) => {
                  const error_message = e.error.message ? e.error.message : e.message;
                  const keyToTranslate = error_message;
                  const department = e.error.department ? e.error.department : e.department;

                  this.errorDialogMessage = await this.translate.get(keyToTranslate).toPromise() + ' < ' + department.Code + ' >';
                  this._dialogService.open(this.errorDialog.dialogId);
              });
            break;
        } // switch
      } // if MessageResult.Accept
    });
    this.subscriptions.push(messageResultEventSubscription);
  }

  CreateAddEditDiscountComponent() {
    this.addEditDiscountComponentContainer.clear();
    const componentFactory         = this._componentFactoryResolver.resolveComponentFactory(AddEditDiscountsComponent);
    this.addEditDiscountComponent = this.addEditDiscountComponentContainer.createComponent(componentFactory);

    this.addEditDiscountComponent.instance.discountDto  = this.discountDto;
    this.addEditDiscountComponent.instance.title        = this.add_edit_title;
    this.addEditDiscountComponent.instance.operation    = this.operation;
    this.addEditDiscountComponent.instance.messageStyle = this.messageStyles.Standard;

    this.addEditDiscountComponent.instance.visible =  true;
  }

  EditAlternativeDiscountTable() {
    this.editAlternativeDiscountTable = true;
    this.alternativeDiscountDto = new AlternativeDiscountDto(
      '',
      null,
      '',
      '',
      0,
    );
  }

  async AddAlternativeDiscountTable() {
    if ( this.alternativeDiscountDto.Name !== '' ) {
      this.operation = 'AddAlternativeDiscountTable';

      const keyToTranslate = 'Are you sure to create a discount table';
      this.warningDialogMessage  = await this.translate.get( keyToTranslate).toPromise() + ' < ' + this.alternativeDiscountDto.Name + ' >';
      this._dialogService.open(this.warningDialog.dialogId);

    } else {
        const keyToTranslate = 'Fill the required fields';
        this.errorDialogMessage  = await this.translate.get( keyToTranslate ).toPromise();
        this._dialogService.open(this.errorDialog.dialogId);
    }
  }

  CancelAddAlternativeDiscountTable() {
    this.editAlternativeDiscountTable = false;
  }

  async RemoveAlternativeDiscountTable() {
    this.operation = 'RemoveAlternativeDiscountTable';
    this.newAlternativeDiscount = null;

    const keyToTranslate = 'Are you sure to remove a discount table';
    this.warningDialogMessage  = await this.translate.get( keyToTranslate).toPromise() + ' <' + this.alternativeDiscountDto.Name + '>';
    this._dialogService.open(this.warningDialog.dialogId);
  }

  EditDiscount() {
    if ( this.selectedDiscount ) {
      this.discountDto = this.selectedDiscount;

      this.operation = 'Update';
      this.add_edit_title = 'Edit Discount';
      this.CreateAddEditDiscountComponent();
    }
  }

  public async UpdateMinSumDownPayment_QuotesToApplyDiscount() {
    if ( this.generalDisountTableSelected ) {

      const update = { MinSumDownPayment_QuotesToApplyDiscount : this.minSumDownPaymentQuotesToApplyDiscount_General };

      this._developsService.UpdateDevelop(this.currentDevelop._id, update).toPromise()
        .then(async (response) => {
          if (response) {
            this.GetDevelops();

            const keyToTranslate = 'Updated Minimum (DOWN PAYMENT + QUOTES) % to apply Discount';
            this.successToast.message = await this.translate.get( keyToTranslate).toPromise();
            this.successToast.instance.show();
          }
        })
        .catch((e) => {
          console.log(e.error.message);
        });

    } else {

        if ( this.selectedAlternativeDiscount ) {
          const param = { MinSumDownPayment_QuotesToApplyDiscount: this.minSumDownPaymentQuotesToApplyDiscount_DiscountTableSelected };

          this._discountsService.UpdateAlternativesDiscountParam(this.selectedAlternativeDiscount._id, param).toPromise()
            .then(async (response) => {
              if (response) {
                this.GetAlternativesDiscountsList();

                const keyToTranslate = 'Updated Minimum (DOWN PAYMENT + QUOTES) % to apply Discount';
                this.successToast.message = await this.translate.get( keyToTranslate).toPromise();
                this.successToast.instance.show();
              }
            })
            .catch((e) => {
              console.log(e.error.message);
            });

        } else {
            console.log('selectedAlternativeDiscount ==> ' +  this.selectedAlternativeDiscount)
        }

    }
  }

  public redirectTo(uri:string){
    this._router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this._router.navigate([uri]));
 }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
