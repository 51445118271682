<dx-popup
  height="auto"
  width="auto"
  [position]="{my: 'center center', at: 'center center', of: 'window', offset: '0 10'}"
  [showTitle]="false"
  [showCloseButton]="false"
  [(visible)]="visible">

  <div *dxTemplate="let data of 'content'">
    <dx-scroll-view>
      <div class="card popup-card "
         [ngClass]="classes.getValue(messageStyle)">

      <div
        class="card-header-modal font-weight-bold">
        {{title | translate}}
      </div>

      <div class="card-body">

        <div class="container">
          <dx-form
            [formData]="commissionConceptDto"
            [colCount]="2">

            <dxi-item
              *ngIf="operation === 'Add'"
              [colSpan]="2"
              [editorType]="'dxSelectBox'"
              [label]="{ visible: true, location: 'top', alignment: 'left', showColon: false, text:'Develop' | translate }"
              [dataField]="'Develop'"
              [editorOptions]="{
                 dataSource: develops,
                 displayExpr: 'Name',
                 valueExpr:'_id',
                 elementAttr: {class: 'percent-reference'}}" >
              <dxi-validation-rule type="required" message="Develop is required"></dxi-validation-rule>
            </dxi-item>
            <dxi-item
              *ngIf="operation !== 'Add'"
              [colSpan]="2"
              [editorType]="'dxTextBox'"
              [label]="{ visible: true, location: 'top', alignment: 'left', showColon: false, text:'Develop' | translate }"
              [dataField]="'Develop.Name'"
              [editorOptions]="{disabled: true, elementAttr: {class: 'percent-reference'}}" >
            </dxi-item>

            <dxi-item
              [editorType]="'dxTextBox'"
              [label]="{ visible: true, location: 'top', alignment: 'left', showColon: false, text:'Code' | translate }"
              [dataField]="'Code'"
              [editorOptions]="{disabled: operation !== 'Add', elementAttr: {class: 'percent-reference'}}" >
              <dxi-validation-rule type="required" message="Code is required"></dxi-validation-rule>
            </dxi-item>

            <dxi-item
              [editorType]="'dxTextBox'"
              [label]="{ visible: true, location: 'top', alignment: 'left', showColon: false, text:'Name' | translate }"
              [dataField]="'Display_Name'"
              [editorOptions]="{elementAttr: {class: 'percent-reference'}}" >
              <dxi-validation-rule type="required" message="Display Name is required"></dxi-validation-rule>
            </dxi-item>

            <dxi-item
              [editorType]="'dxTextBox'"
              [colSpan]="2"
              [label]="{ visible: true, location: 'top', alignment: 'left', showColon: false, text:'Description' | translate}"
              [dataField]="'Description'"
              [editorOptions]="{elementAttr: {class: 'percent-reference'}}">
            </dxi-item>

            <dxi-item
              editorType="dxNumberBox"
              [label]="{ visible: true, location: 'top', alignment: 'left', showColon: false, text:'Owner Commission' | translate: { param : ''}}"
              [dataField]="'SaleValuePercent'"
              [editorOptions]= "{format:'#,##0.## %', elementAttr: {class: 'percent-reference'}}">
            </dxi-item>

          </dx-form>
        </div>

        <br><!--Active_Referred-->
        <div class="container">
          <dx-check-box style="margin-bottom: 8px"
                        [text]="'Referred Commission' | translate: { param : ''}"
                        [(value)]="commissionConceptDto.Active_Referred"
                        (onValueChanged)="valueChanged_Active_Referred($event)">
          </dx-check-box>

          <div class="row">
            <div class="col">
              <dx-form
                [formData]="commissionConceptDto"
                [colCount]="2"
                [validationGroup]="'departmentDtoValidation'">

                <dxi-item
                  [label]="{ visible: false, location: 'top', alignment: 'left', showColon: false, text:'Referred Commission' | translate: { param : ''}}"
                  [dataField]="'ReferredPercent'"
                  [editorOptions]= "{disabled: !commissionConceptDto.Active_Referred,
                                 format:'#,##0.## %',
                                 elementAttr: {class: 'percent-reference'}}">
                </dxi-item>

              </dx-form>
            </div>
          </div>

        </div>
        <br>

      </div>

      <div class="card-footer-modal">
        <div class="pull-right">

          <dx-button class="btn-avenir-celest-blue"
            [text]="'Accept' | translate"
            [disabled]="commissionConceptDto.Active_Referred && commissionConceptDto.ReferredPercent <= 0"
            (onClick)="addEditButtonAccept(messageResults.Accept)"
            [useSubmitBehavior]="true">
          </dx-button>

          <dx-button class="dlg-button-margin btn-avenir-red"
                     [text]="'Cancel' | translate"
                     (onClick)="addEditButtonCancel(messageResults.Cancel)">
          </dx-button>
          &nbsp;
        </div>
      </div>
    </div>
    </dx-scroll-view>

  </div>

</dx-popup>
