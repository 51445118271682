import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

// Gards and Services
import { P403Component } from './components/403.component';
import { P500Component } from './components/500.component';
import { P501Component } from './components/501.component';
import { LoginComponent } from './components/login.component';

import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthInterceptorService} from '../../services/services/auth-interceptor.service';
import { AuthService } from '../../services/services/auth.service';
import { SecurityService } from '../../services/services/security.service';
import { SharedModule } from '../shared/shared.module';
import { AuthGuard } from './guards/auth.guard';
import { SecurityGuard } from './guards/security.guard';

@NgModule({
    declarations: [
        LoginComponent,
        P403Component,
        P500Component,
        P501Component,
    ],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
    ],
    providers: [
        AuthGuard,
        AuthService,
        SecurityGuard,
        SecurityService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptorService,
          multi: true,
        },
    ], // ,entryComponents: [LoginComponent]
})

export class SecurityModule {}
