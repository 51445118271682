import { Injectable } from '@angular/core';
import { BaseService } from './base/base.service';

@Injectable({
  providedIn: 'root',
})

export class CustomersService {

  constructor(private _baseService: BaseService ) {}

  public AddCustomer( customer ) {
    return this._baseService.Post('addcustomer', customer);
  }

  public GetCustomers() {
    return this._baseService.Get('getcustomers');
  }

  public GetCustomerById(customerId) {
    return this._baseService.Get('getcustomerbyid/' + customerId);
  }

  public UpdateCustomer( customerId, customer ) {
    return this._baseService.Put('updatecustomer/' + customerId, customer);
  }

  public DeleteCustomer( customerId ) {
    return this._baseService.Delete('deletecustomer/' + customerId);
  }

  public RemoveAllCustomers() {
    return this._baseService.Delete('removeallcustomers');
  }
}
