import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BaseService} from '../../../services/base/base.service';
import {NotificationsService} from '../../../services/notifications.service';

@Component({
  selector: 'app-aside',
  templateUrl: './app-aside.component.html',
})
export class AppAsideComponent {

  /**
   * Arreglo de notificaciones que se actualizan cada 10s desde el componente 'FullLayoutComponent'
   */
  @Input() notifications: any[];

  /**
   * Arreglo de mensajes que se actualizan cada 10s desde el componente 'FullLayoutComponent'
   */
  @Input() messages: any[];

  /**
   * Metodo exitador Output para lanzar metodo de componente padre 'FullLayoutComponent' para actualizar el arreglo de
   * notificaciones tras el borrado de alguna
   */
  @Output() refreshNotifications = new EventEmitter();

  constructor( private _notificationsService: NotificationsService,
               public _baseService: BaseService) {
  }

  RemoveNotification(notificationId) {
    this._notificationsService.RemoveNotification(notificationId).toPromise()
      .then((response) => {
         // console.log((response as any).message);
         this.refreshNotifications.emit(true);
      })
      .catch((error) => {
        console.log(error.error.message);
      });
  }

}
