<aside class="aside-menu">
  <tabset>
    <tab [active]="true">
      <ng-template tabHeading><i class="icon-bell"></i></ng-template>
      <div class="m-0 py-2 text-muted text-center text-uppercase custom-border">
        <small><b>{{'Notifications' | translate}}</b></small>
      </div>
      <!-- <hr class="mx-0 my-0">
      <hr class="mx-0 my-0"> -->



      <div *ngFor="let notification of notifications">
        <div class="callout m-0 py-3 callout-{{notification.CcsClass}}">

          <div>
            <div class="avatar">
              <img *ngIf="!notification.ExecutorUser || notification.ExecutorUser.Image === ''" class="img-avatar" src="assets/img/avatars/DefaultUser.png" alt="User image"/>
              <img *ngIf="notification.ExecutorUser && notification.ExecutorUser.Image !== ''" class="img-avatar" src="{{ _baseService.apiUrl + '/get-user-image/' + notification.ExecutorUser.Image }}" [alt]=notification.ExecutorUser.Image />
              <span class="avatar-status badge-success"></span>
            </div>


            <small *ngIf="!notification.ExecutorUser || notification.ExecutorUser.Description === ''" class="text-truncate ml-1 font-weight-bold">{{ 'User Description' }}</small>
            <small *ngIf="notification.ExecutorUser && notification.ExecutorUser.Description !== ''" class="text-truncate ml-1 font-weight-bold">{{ notification.ExecutorUser.Description }}</small>
            <br>
            <small *ngIf="!notification.ExecutorUser || notification.ExecutorUser.Email === ''"  class="text-muted ml-1 font-weight-bold">{{ 'Email' }}</small>
            <small *ngIf="notification.ExecutorUser && notification.ExecutorUser.Email !== ''" class="text-muted ml-1 font-weight-bold">{{ notification.ExecutorUser.Email }}</small>
          </div>

          <div>
            <small class="text-muted-aside-menu float-right mt-1 font-weight-bold">{{notification.NotificationDate | date}}</small>
          </div>

          <div>
            <div class="py-3 pb-5 mr-3 float-left">
              <div *ngIf="notification.Subject === 'Reservation' " class="icon-notebook"></div>
              <div *ngIf="notification.Subject === 'Commission'" class="icon-wallet"></div>
            </div>
            <small class="text-truncate font-weight-bold">{{ notification.Subject | translate: { param : ''} }}</small>
          </div>

          <small class="text-muted-aside-menu"><p>{{notification.Message}}</p></small>

          <div class="py-3">
            <button type="button" class="btn btn-danger-notification-aside btn-sm float-right" (click)="RemoveNotification(notification._id)" >{{"Delete" | translate}}</button>
          </div>

        </div>
        <!-- <hr class="mx-3 my-0"> -->
        <hr class="my-0">
      </div>
    </tab>

    <!--<tab [active]="false">-->
      <!--<ng-template tabHeading><i class="fa fa-envelope-o"></i></ng-template>-->
      <!--<div class="m-0 py-2 text-muted text-center bg-light text-uppercase">-->
        <!--<small><b>{{'Messages' | translate }}</b></small>-->
      <!--</div>-->
      <!--<hr class="mx-0 my-0">-->
      <!--<div class="p-2">-->

        <!--<div class=" m-0 py-3">-->
          <!--<div class="avatar float-right">-->
            <!--<img src="assets/img/avatars/7.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">-->
          <!--</div>-->
          <!--<div>Meeting with <strong>AdrianOG</strong></div>-->
          <!--<small class="text-muted mr-3"><i class="icon-calendar"></i>&nbsp; 1 - 3pm</small>-->
          <!--<small class="text-muted"><i class="icon-location-pin"></i>&nbsp; Palo Alto, CA </small>-->
          <!--<div class="mt-2">-->
            <!--<button type="button" class="btn btn-primary btn-sm float-left">Message</button>-->
            <!--<button type="button" class="btn btn-danger btn-sm float-right">Delete</button>-->
          <!--</div>-->
        <!--</div>-->

        <!--&lt;!&ndash;<hr class="mx-2 my-0">&ndash;&gt;-->
      <!--</div>-->

      <!--&lt;!&ndash;<hr class="mx-0 my-0">&ndash;&gt;-->
      <!--&lt;!&ndash;<hr class="transparent mx-0 my-0">&ndash;&gt;-->
    <!--</tab>-->


  </tabset>
</aside>
