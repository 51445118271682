import {CommonModule} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {DevExtremeModule, DxNumberBoxModule} from 'devextreme-angular';
import {DxDateBoxModule} from 'devextreme-angular';
import {NgxSpinnerModule, NgxSpinnerService} from 'ngx-spinner';
import {DialogService} from '../../services/base/dialog.service';
import {AddEditChangeUserPasswordComponent} from './components/add-edit-change-user-password/add-edit-change-user-password.component';
import {AddEditCommissionConceptComponent} from './components/add-edit-commission-concept/add-edit-commission-concept.component';
import {AddEditDiscountsComponent} from './components/add-edit-discounts/add-edit-discounts';
import {AddEditUserComponent} from './components/add-edit-user/add-edit-user.component';
import {AssignationCommissionConceptComponent} from './components/assignation-commission-concept/assignation-commission-concept.component';
import {CustomDialogComponent} from './components/custom-dialog/custom-dialog.component';
import {ReserveDepartmentComponent} from './components/reserve-department/reserve-department.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../../../assets/i18n/', '.json');
}

const APP_COMPONENTS = [
  CustomDialogComponent,
];

@NgModule({
  declarations: [
    AddEditUserComponent,
    AddEditChangeUserPasswordComponent,
    CustomDialogComponent,
    ReserveDepartmentComponent,
    AddEditCommissionConceptComponent,
    AssignationCommissionConceptComponent,
    AddEditDiscountsComponent,
  ],
  imports: [
    CommonModule,
    DevExtremeModule,
    NgxSpinnerModule,
    DxDateBoxModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [DialogService, NgxSpinnerService],
  exports: [
    CommonModule,
    DevExtremeModule,
    NgxSpinnerModule,
    DxDateBoxModule,
    DxNumberBoxModule,
    TranslateModule,
    APP_COMPONENTS,    // Components
  ],
  entryComponents: [
    AddEditUserComponent,
    AddEditChangeUserPasswordComponent,
    ReserveDepartmentComponent,
    AddEditCommissionConceptComponent,
    AssignationCommissionConceptComponent,
    AddEditDiscountsComponent,
  ],
})
export class SharedModule {
}
