<header class="app-header navbar">
  <button class="navbar-toggler d-lg-none" type="button" appMobileSidebarToggler>
    <span class="navbar-toggler-icon"></span>
  </button>
  <img class="logo-header" src="assets/img/logo-symbol.png" />
  <button class="navbar-toggler d-md-down-none mr-auto" type="button" appSidebarToggler>
    <span class="navbar-toggler-icon"></span>
  </button>

  <ul style="margin-right: 50px" class="nav navbar-nav ml-auto">

    <li *ngIf="lang === 'en'">
      <a class="nav-link header-language" (click)="switchLanguage('es')">
        <img class='img-flags-language' src="{{'assets/img/flags/flag-EN.png'}}" [alt]="'flag-EN.png'" />
      </a>
    </li>

    <li *ngIf="lang === 'es'">
      <a class="nav-link header-language" (click)="switchLanguage('en')">
        <img class='img-flags-language' src="{{'assets/img/flags/flag-ES.png'}}" [alt]="'flag-ES.png'" />
      </a>
    </li>

  </ul>
</header>

<div class="app-body" >
  <app-sidebar></app-sidebar>

  <!-- Main content -->
  <main class="main">

    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div><!-- /.conainer-fluid -->

  </main>

</div>
<app-footer></app-footer>
