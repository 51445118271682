import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import * as Collections from 'typescript-collections';
import {DialogService} from '../../../../services/base/dialog.service';
import {MessageResult, MessageStyle, MessageType} from '../../helpers/global-enums';
import {Guid} from '../../helpers/guid.helper';

@Component({
  selector: 'app-custom-dialog',
  templateUrl: './custom-dialog.component.html',
})
export class CustomDialogComponent implements OnInit {

  dialogId = Guid.newGuid();

  /**
   * Contiene el título del mensaje a mostrar
   * @type {string}
   */
  @Input() title = '';

  /**
   * Contiene el texto del mensaje a mostrar
   * @type {string}
   */
  @Input() message = '';

  /**
   * Contiene una version HTML del mensaje a mostrar
   * @type {string}
   */
  @Input() innerHTML = '';

  /**
   * Contiene el estilo del mensaje a mostrar
   * @type {MessageStyle}
   */
  @Input() messageStyle: MessageStyle;

  /**
   * Contiene el tipo del mensaje a mostrar
   * @type {MessageType}
   */
  @Input() messageType: MessageType;

  /**
   * Contiene la posición del popup
   */
  @Input() position: any = null;

  /**
   * Contiene el tipo del mensaje a mostrar
   * @type {string}
   */
  @Input() param: string;

  /**
   * Contiene las posibles clases a aplicar al mensaje en dependencia del {@link messageStyles}
   * @type {Dictionary<MessageStyle, string>}
   */
  classes = new Collections.Dictionary<MessageStyle, string>();

  /**
   * @ignore
   */
  messageStyles = MessageStyle;

  /**
   * @ignore
   */
  messageTypes = MessageType;

  /**
   * @ignore
   */
  messageResults = MessageResult;

  @Output() messageResultEvent = new EventEmitter<MessageResult>();

  isOpened = false;

  constructor(private dialogService: DialogService) {
  }

  /**
   * Método que maneja el evento click de los botones del mensaje
   */
  handleMessageButtonClickedEvent(messageResult: MessageResult) {
    this.dialogService.navigateAwaySelection$.next(
      messageResult === MessageResult.Accept || messageResult === MessageResult.Continue,
    );
    this.messageResultEvent.emit(messageResult);
    this.dialogService.close(this.dialogId);
  }

  /**
   * Inicializa el componente
   */
  ngOnInit(): void {
    this.dialogService.registerDialog(this);
    this.classes.setValue(MessageStyle.Standard, 'bg-light text-center');
    this.classes.setValue(MessageStyle.Information, 'bg-info text-center');
    this.classes.setValue(MessageStyle.Warning, 'bg-warning text-center');
    this.classes.setValue(MessageStyle.Error, 'bg-danger text-center');
    this.classes.setValue(MessageStyle.Success, 'bg-success text-center');
  }

}
