import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as Collections from 'typescript-collections';
import {DiscountDto} from '../../../../dto-models/discount-dto.model';
import {ParamsService} from '../../../../services/base/params.service';
import {MessageResult, MessageStyle} from '../../helpers/global-enums';

@Component({
  selector: 'app-add-edit-discounts',
  templateUrl: './add-edit-discounts.html',
})
export class AddEditDiscountsComponent implements OnInit {

  /**
   * Controla la visibilidad del poppup
   */
  @Input() visible: boolean;

  /**
   * Contiene el título del mensaje a mostrar
   * @type {string}
   */
  @Input() title = '';

  /**
   * Contiene el estilo del mensaje a mostrar
   * @type {MessageStyle}
   */
  @Input() messageStyle: MessageStyle;

  @Input() discountDto: DiscountDto;
  @Input() operation;

  @Output() messageResultEvent = new EventEmitter<MessageResult>();

  /**
   * Contiene las posibles clases a aplicar al mensaje en dependencia del {@link messageStyles}
   * @type {Dictionary<MessageStyle, string>}
   */
  classes = new Collections.Dictionary<MessageStyle, string>();
  messageStyles = MessageStyle;
  messageResults = MessageResult;

  discountType: string;

  constructor() {
  }

  /**
   * Método que maneja el evento del boton Accept del AddEdit
   */
  addEditButtonAccept(messageResult: MessageResult) {
    // if ( event.validationGroup.validate().isValid ) {
    ParamsService.addEditDiscountComponentEvent.emit(messageResult);
    // }
  }

  /**
   * Método que maneja el evento del boton Cancel del AddEdit
   */
  addEditButtonCancel(messageResult: MessageResult) {
    ParamsService.addEditDiscountComponentEvent.emit(messageResult);
  }

  /**
   * Inicializa el componente
   */
  ngOnInit() {
    this.discountType = this.discountDto.Type;

    this.classes.setValue(MessageStyle.Standard, 'bg-light');
    this.classes.setValue(MessageStyle.Information, 'bg-info text-center');
    this.classes.setValue(MessageStyle.Warning, 'bg-warning text-center');
    this.classes.setValue(MessageStyle.Error, 'bg-danger text-center');
    this.classes.setValue(MessageStyle.Success, 'bg-success text-center');
  }

}
