<dx-popup
  height="auto"
  width="auto"
  [position]="{my: 'center center', at: 'center center', of: 'window', offset: '0 10'}"
  [showTitle]="false"
  [showCloseButton]="false"
  [(visible)]="visible">

  <div *dxTemplate="let data of 'content'">
    <dx-scroll-view>
      <div class="card popup-card "
         [ngClass]="classes.getValue(messageStyle)">

      <div
        class="card-header-modal font-weight-bold">
        {{title | translate}}
      </div>

      <div class="card-body">
        <dx-form
          [formData]="discountDto"
          [colCount]="2">

          <dxi-item *ngIf="discountType === 'down_payment'"
                    cssClass="dx-data-grid-avenir"
                    editorType="dxNumberBox"
                    [label]="{ visible: true, location: 'top', alignment: 'left', showColon: false, text:'Down Payment' | translate: { param : '%'} }"
                    [dataField]="'Percent'"
                    [editorOptions]="{disabled: operation !== 'Add',
                                      format:'#,##0.## \'%\'',
                                      showSpinButtons: false,
                                      elementAttr: {class: 'percent-reference'}}" >
            <dxi-validation-rule type="required" message="Percent is required"></dxi-validation-rule>
          </dxi-item>
          <dxi-item *ngIf="discountType === 'quotes'"
            cssClass="dx-data-grid-avenir"
            editorType="dxNumberBox"
            [label]="{ visible: true, location: 'top', alignment: 'left', showColon: false, text:'Quotes' | translate: { param : '%'} }"
            [dataField]="'Percent'"
            [editorOptions]="{disabled: operation !== 'Add',
                              format:'#,##0.## \'%\'',
                              showSpinButtons: false,
                              elementAttr: {class: 'percent-reference'}}" >
            <dxi-validation-rule type="required" message="Percent is required"></dxi-validation-rule>
          </dxi-item>

          <dxi-item
            cssClass="dx-data-grid-avenir"
            editorType="dxNumberBox"
            [label]="{ visible: true, location: 'top', alignment: 'left', showColon: false, text:'Discount' | translate: { param : '%'} }"
            [dataField]="'Discount'"
            [editorOptions]="{format:'#,##0.## \'%\'',
                              showSpinButtons: false,
                              elementAttr: {class: 'percent-reference'}}" >
            <dxi-validation-rule type="required" message="Discount is required"></dxi-validation-rule>
          </dxi-item>

        </dx-form>
        <br>

      </div>

      <div class="card-footer-modal">
        <div class="pull-right">

          <dx-button class="btn-avenir-celest-blue"
            [text]="'Accept' | translate"
            [disabled]="false"
            (onClick)="addEditButtonAccept(messageResults.Accept)"
            [useSubmitBehavior]="true">
          </dx-button>

          <dx-button class="dlg-button-margin btn-avenir-red"
                     [text]="'Cancel' | translate"
                     (onClick)="addEditButtonCancel(messageResults.Cancel)">
          </dx-button>
          &nbsp;
        </div>
      </div>
    </div>
    </dx-scroll-view>

  </div>

</dx-popup>
