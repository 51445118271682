import { Injectable } from '@angular/core';
import {UserService} from '../user.service';

@Injectable()
export class SecurityService {

  constructor( private _userService: UserService ) {}

  /**
   * @method isAllowed
   * @description Método que comprueba si el usuario logeado tiene permitido acceder a un url*/
  isAllowed( roles ) {
    // let findRole = false;
    const promise = new Promise(
      (resolve, reject) => {
        const curentUser_role  = this._userService.CurrentUser().user.Role;

        const findRole = roles.find((role) => role === curentUser_role );

        // roles.forEach((role)=>{
        //   if (curentUser_role === role)
        //     findRole = true;
        // });
        resolve (findRole);
      }
    );
    return promise;
  }


}

