
<div class="animated fadeIn">

  <div class="card-heather" style= "height: 15px" >
    <div class="text-muted"></div>
  </div>

  <div class="card card-accent-primary" >
    <div class="card-body">

      <div class="col-sm-5">
        <img class="img-title" src="assets/img/RECURSOS/discounts.png" alt="discounts.png"/>
        <h4 class="checkbox-avenir-title mb-0">{{'Discounts' | translate}}</h4>
        <br>
      </div>

      <br>
      <div>
        <div *ngIf="currentDevelop" class="col-sm-4">
          <h5 class="card-title mb-0">{{'Develop' | translate}}</h5>
          <dx-select-box
            style="margin-top: 5px"
            [disabled]="false"
            [dataSource]="develops"
            [displayExpr]="'Name'"
            [valueExpr]="'_id'"
            [value]="currentDevelop._id"
            [placeholder]="'Develop' | translate"
            width="auto"
            (onValueChanged)="onDevelopSelectionChanged($event)"
            [elementAttr]="{class: 'percent-reference'}">
          </dx-select-box>
        </div>

        <br><!--<div>Mínimo % (Enganche + Mensualidades) para aplicar descuento</div>-->
        <div class="row" style="margin-left: 1px">
          <div class="col-sm-4">
            <div class="checkbox-avenir-subtitle float-left dlg-button-margin" style="margin-top: 7px">
              <strong>{{'Minimum % (Down Payments + Quotes) to Apply Discount' | translate }}</strong>
            </div>
          </div>
          <div class="col-sm-4">
            <dx-number-box class="float-left dlg-button-margin"
                           [(value)]="minSumDownPaymentQuotesToApplyDiscount_General"
                           [showSpinButtons]=true
                           [width]= 150
                           [step]= 10
                           [min]= 0
                           [max]= 100
                           [disabled]="!roleAdminUser"
                           [elementAttr]="{class: 'percent-reference'}">
            </dx-number-box>
            <dx-button class="btn-avenir-brown-short float-left dlg-button-margin"
                       type="default"
                       icon="check"
                       (click)="UpdateMinSumDownPayment_QuotesToApplyDiscount()"
                       [disabled]="!currentDevelop"
                       [visible]="roleAdminUser">
            </dx-button>
          </div>
        </div>
        <br><br>

        <div class="card" style=" margin-left: 10px !important;">
          <div class="card-header checkbox-avenir-subtitle">
            {{'DISCOUNT TABLES' | translate}}
          </div>

          <br>
          <div *ngIf="currentDevelop" class="container-fluid">
            <div class="row">

              <div class="col-sm-4">

                <div>
                  <h6 class="card-title mb-0">{{'Discount Table' | translate}}</h6>
                  <dx-select-box
                      style="margin-top: 7px"
                      [disabled]="!roleAdminUser"
                      [dataSource]="alternativesDiscountsList"
                      [displayExpr]="'Name'"
                      [valueExpr]="'_id'"
                      [value]="selectedAlternativeDiscount._id"
                      [placeholder]="'Discount Table' | translate"
                      width="auto"
                      [elementAttr]="{class: 'percent-reference'}"
                      (onValueChanged)="alternativesDiscountsList_SelectionChanged($event)">
                  </dx-select-box>

                  <br>
                  <div *ngIf="roleAdminUser">
                    <button type="button" class="d-lg-none btn btn-avenir-red-short btn-sm float-left"
                            [disabled]="generalDisountTableSelected || editAlternativeDiscountTable"
                            (click)="RemoveAlternativeDiscountTable()">{{'Delete' | translate}}
                    </button>
                    <dx-button class="d-md-down-none btn-avenir-red float-left" [text]="'Delete' | translate"
                            [disabled]="generalDisountTableSelected || editAlternativeDiscountTable" (onClick)="RemoveAlternativeDiscountTable()">
                    </dx-button>

                    <button type="button" class="d-lg-none btn btn-avenir-green-short btn-sm float-right dlg-button-margin"
                            [disabled]="editAlternativeDiscountTable"
                            (click)="EditAlternativeDiscountTable()">{{'Add' | translate}}
                    </button>
                    <dx-button class="d-md-down-none btn-avenir-green float-right dlg-button-margin" [text]="'Add' | translate"
                            [disabled]="editAlternativeDiscountTable"
                            (onClick)="EditAlternativeDiscountTable()">
                    </dx-button>
                  </div>
                  <br><br>
                </div>

                <div *ngIf="editAlternativeDiscountTable">
                  <br>
                  <dx-form
                    [formData]="alternativeDiscountDto">

                    <dxi-item
                      [label]="{ visible: true, location: 'top', alignment: 'left', showColon: false, text:'Name' | translate}"
                      [dataField]="'Name'"
                      [editorOptions]="{ elementAttr: {class: 'percent-reference'}}">
                      <dxi-validation-rule type="required" message="Name is required"></dxi-validation-rule>
                    </dxi-item>

                    <dxi-item
                      [label]="{ visible: true, location: 'top', alignment: 'left', showColon: false, text:'Description' | translate}"
                      [dataField]="'Description'"
                      [editorOptions]="{ elementAttr: {class: 'percent-reference'}}">
                    </dxi-item>

                  </dx-form>

                  <br>
                  <div>
                    <div>
                      <button type="button" class="d-lg-none btn btn-avenir-ligth-short btn-sm float-left"
                              (click)="CancelAddAlternativeDiscountTable()">{{'Cancel' | translate}}
                      </button>
                      <dx-button class="d-md-down-none btn-avenir-ligth float-left" [text]="'Cancel' | translate"
                                 (onClick)="CancelAddAlternativeDiscountTable()">
                      </dx-button>
                    </div>

                    <div>
                      <button type="button" class="d-lg-none btn btn-avenir-strong-blue-short btn-sm float-right dlg-button-margin"
                              (click)="AddAlternativeDiscountTable()">{{'Add' | translate}}
                      </button>
                      <dx-button class="d-md-down-none btn-avenir-strong-blue float-right dlg-button-margin" [text]="'Add' | translate"
                                 (onClick)="AddAlternativeDiscountTable()">
                      </dx-button>
                    </div>

                    <br><br>
                  </div>
                </div>

              </div>

              <div class="col-sm-8" style="padding-left: 30px">
                <!--<div class="checkbox-avenir-subtitle">-->
                  <!--<div class="text-muted">{{'DISCOUNT TABLES' | translate}} {{selectedAlternativeDiscount.Name}}</div>-->
                <!--</div>-->

                <div *ngIf="!generalDisountTableSelected">
                  <br><!--<div>Mínimo % (Enganche + Mensualidades) para aplicar descuento de TABLA ALTERNATIVA DE DESCUENTO</div>-->
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="checkbox-avenir-subtitle float-left dlg-button-margin" style="margin-top: 7px">
                        {{'Minimum % (Down Payments + Quotes) to Apply Discount' | translate }}
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <dx-number-box class="float-left dlg-button-margin"
                                     [(value)]="minSumDownPaymentQuotesToApplyDiscount_DiscountTableSelected"
                                     [showSpinButtons]=true
                                     [width]= 150
                                     [step]= 10
                                     [min]= 0
                                     [max]= 100
                                     [disabled]="!roleAdminUser"
                                     [elementAttr]="{class: 'percent-reference'}">
                      </dx-number-box>
                      <dx-button class="btn-avenir-brown-short float-left dlg-button-margin"
                                 type="default"
                                 icon="check"
                                 (click)="UpdateMinSumDownPayment_QuotesToApplyDiscount()"
                                 [disabled]="!currentDevelop"
                                 [visible]="roleAdminUser">
                      </dx-button>
                    </div>
                  </div>
                  <br>
                </div>

                <dx-form style="margin-top: 5px">
                  <br>
                  <dxi-item itemType="tabbed">

                    <dxi-tab
                      [title]="'Down Payment' | translate: { param : ''}">
                      <dx-data-grid
                        #gridDiscounts_downPayments
                        [dataSource]="downPayments_discounts_dataSource"
                        [rowAlternationEnabled]="false"
                        [columnAutoWidth]="true"
                        [cacheEnabled]="false"
                        (onCellPrepared)="handleCellPrepared($event)"
                        (onSelectionChanged)="handleGridSelectionChangedEvent($event)">

                        <dxo-paging
                          [pageSize]="15">
                        </dxo-paging>

                        <dxo-pager
                          [showPageSizeSelector]="true"
                          [allowedPageSizes]="[5,10,20]"
                          [showInfo]="true">
                        </dxo-pager>

                        <dxo-scrolling
                          showScrollbar="always">
                        </dxo-scrolling>

                        <dxo-selection
                          [mode]="'single'"
                          selectAllMode="page"
                          showCheckBoxesMode="none">
                        </dxo-selection>

                        <!--[format]="'#,##0.## %'"-->
                        <dxi-column cssClass="dx-data-grid-avenir" dataField = "Percent" [sortIndex]="1" [sortOrder]="'asc'" [caption]="'Down Payment' | translate: { param : '%'}" cellTemplate="cellTemplatePercent" [alignment]="'center'"></dxi-column>
                        <dxi-column cssClass="dx-data-grid-avenir" dataField = "Discount" [caption]="'Discount' | translate: { param : '%'}"  cellTemplate="cellTemplatePercent" [alignment]="'center'"></dxi-column>
                        <!--<dxi-column dataField = "Extra" [caption]="'Extra' | translate" cellTemplate="cellTemplatePercent" [alignment]="'center'"></dxi-column>-->
                        <!--<dxi-column dataField = "Develop.Name" [caption]="'Develop' | translate" [alignment]="'center'"></dxi-column>-->

                        <div *dxTemplate="let data of 'cellTemplatePercent'" (click)="SelectDiscountDownPaymentsRow(data)">
                          <a>{{data.value + ' %'}}</a>
                        </div>

                      </dx-data-grid>
                    </dxi-tab>

                    <dxi-tab
                      [title]="'Quotes' | translate: { param : ''}">
                      <dx-data-grid
                        #gridDiscounts_quotes
                        [dataSource]="quotes_discounts_dataSource"
                        [rowAlternationEnabled]="false"
                        [columnAutoWidth]="true"
                        [cacheEnabled]="false"
                        (onCellPrepared)="handleCellPrepared($event)"
                        (onSelectionChanged)="handleGridSelectionChangedEvent($event)">

                        <dxo-paging
                          [pageSize]="15">
                        </dxo-paging>

                        <dxo-pager
                          [showPageSizeSelector]="true"
                          [allowedPageSizes]="[5,10,20]"
                          [showInfo]="true">
                        </dxo-pager>

                        <dxo-scrolling
                          showScrollbar="always">
                        </dxo-scrolling>

                        <dxo-selection
                          [mode]="'single'"
                          selectAllMode="page"
                          showCheckBoxesMode="none">
                        </dxo-selection>

                        <!--[format]="'#,##0.## %'"-->
                        <dxi-column cssClass="dx-data-grid-avenir" dataField = "Percent" [sortIndex]="1" [sortOrder]="'asc'" [caption]="'Quotes' | translate: { param : '%'}" cellTemplate="cellTemplatePercent" [alignment]="'center'"></dxi-column>
                        <dxi-column cssClass="dx-data-grid-avenir" dataField = "Discount" [caption]="'Discount' | translate: { param : '%'}"  cellTemplate="cellTemplatePercent" [alignment]="'center'"></dxi-column>

                        <div *dxTemplate="let data of 'cellTemplatePercent'" (click)="SelectDiscountQuotesRow(data)">
                          <a>{{data.value + ' %'}}</a>
                        </div>

                      </dx-data-grid>
                    </dxi-tab>

                  </dxi-item>
                </dx-form>

              </div>

            </div>
          </div>
          <br>

        </div>
      </div>

    </div>

    <div class="card-footer">
      <div style="padding-right: 10px">
        <div>
          <button type="button" class="d-lg-none btn btn-avenir-celest-blue-short btn-sm float-right dlg-button-margin"
                  [disabled]="!selectedDiscount"
                  (click)="EditDiscount()">{{'Edit' | translate}}
          </button>

          <dx-button class="d-md-down-none btn-avenir-celest-blue float-right dlg-button-margin" [text]="'Edit' | translate"
                     [visible]="roleAdminUser" [disabled]="!selectedDiscount" (onClick)="EditDiscount()">
          </dx-button>

        </div>
        <br>
      </div>
    </div>
  </div>

</div>

<app-custom-dialog
  #SuccessDialog
  [position]="{ my: 'center', at: 'center', of: 'window' }"
  [messageStyle]="messageStyles.Success"
  [messageType]="messageTypes.Default"
  [title]="'Success'"
  [message]="successDialogMessage">
</app-custom-dialog>

<app-custom-dialog
  #WarningDialog
  [position]="{ my: 'center', at: 'center', of: 'window' }"
  [messageStyle]="messageStyles.Warning"
  [messageType]="messageTypes.Confirmation"
  [title]="'Warning'"
  [message]="warningDialogMessage">
</app-custom-dialog>

<app-custom-dialog
  #ErrorDialog
  [position]="{ my: 'center', at: 'center', of: 'window' }"
  [messageStyle]="messageStyles.Error"
  [messageType]="messageTypes.Default"
  [title]="'Error'"
  [message]="errorDialogMessage">
</app-custom-dialog>

<template #addEditDiscountComponentContainer></template>

<dx-toast
  #successToast
  type="success"
  [position]="{ my: 'center top', at: 'center top', of: 'window' }"
  [closeOnClick]="true"
  width="450">
</dx-toast>
