import { Component } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Component({
  templateUrl: '403.component.html'
})
export class P403Component {

  constructor( private _router: Router) { }

  public Back() {
     this._router.navigate(['/dashboard']);
  }

}
