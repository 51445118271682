import { Injectable, EventEmitter } from '@angular/core';
import {Router} from '@angular/router';
import * as CryptoJS from 'crypto-js';
import {BaseService} from './base/base.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {

  rebuildNav = new EventEmitter<boolean>();
  constructor(
    private _baseService: BaseService,
    private _router: Router) {}

  CurrentUser(): any {
     const currentUser = localStorage.getItem('cu_key') ;

     if (currentUser) {
       // Decrypt
       const bytes  = CryptoJS.AES.decrypt( currentUser.toString(), '**AfrontE01securYystems#09B*ackb*Kmoneystems#93**');
       const decrypted = JSON.parse( bytes.toString(CryptoJS.enc.Utf8) );

       // console.log(decrypted);
       return decrypted;
     } else {
        return null;
     }
  }

  Logout() {
    localStorage.removeItem('cu_key');
    localStorage.removeItem('exp');
    this._router.navigate(['/security/login']);
  }

  public GetUsers(role) {
    return this._baseService.Post('getusers', role);
  }

  public GetAdminsUsers(selected_user_id) {
    return this._baseService.Get('get-admins-users/' + selected_user_id);
  }

  public RegisterUser( user ) {
    return this._baseService.Post('register-user', user);
  }

  public AddUser( user ) {
    return this._baseService.Post('add-user', user);
  }

  public UpdateUser( userid, userupd) {
    return this._baseService.Put('updateuser/' + userid, userupd);
  }

  public ChangeUserPassword( userid, userupd) {
    return this._baseService.Put('change-user-password/' + userid, userupd);
  }

  public DeleteUser( userid ) {
    return this._baseService.Delete('deleteuser/' + userid);
  }

  public GetUserById(userId) {
    return this._baseService.Get('getuserbyid/' + userId);
  }

  public SendValidatorCodeForChangePasswordEmail(useremail) {
    return this._baseService.Get('send-validator-code-for-change-password-email/' + useremail);
  }

  public SendValidatorCodeEmail( userDto ) {
    return this._baseService.Post('send-validator-code-email/', userDto);
  }

  public UploadUserImage(email, userId, userimagefile) {
    return this._baseService.BinaryPost('upload-user-image/' + email + '/' + userId, userimagefile);
  }

}

