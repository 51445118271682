export class AlternativeDiscountDto {
  public _id: string;
  public Develop: string;
  public Name: string;
  public Description: string;
  public MinSumDownPayment_QuotesToApplyDiscount: number;

  constructor(
    _id: string,
    Develop: string,
    Name: string,
    Description: string,
    MinSumDownPayment_QuotesToApplyDiscount: number) {

      this._id         = _id;
      this.Develop     = Develop;
      this.Name        = Name;
      this.Description = Description;
      this.MinSumDownPayment_QuotesToApplyDiscount = MinSumDownPayment_QuotesToApplyDiscount;
  }
}
