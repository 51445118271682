<dx-popup
  height="auto"
  [position]="{my: 'center', at: 'center', of: '.screen', offset: '0 50'}"
  [showTitle]="false"
  [showCloseButton]="false"
  [(visible)]="visible">

  <div *dxTemplate="let data of 'content'">
    <!-- 'both' | 'horizontal' | 'vertical' -->
    <dx-scroll-view
      [useNative]="false"
      [scrollByContent]="true"
      [scrollByThumb]="true"
      [height]="480"
      direction="vertical">
      <div class="card popup-card" [ngClass]="classes.getValue(messageStyle)">
        <div
          class="card-header-modal font-weight-bold">
          {{title | translate}}
        </div>

        <div class="card-body">

          <div class="card">
            <div class="card-body">
              <h5 class="card-title mb-0">{{'Commissions Criteries' | translate}}</h5>
              <br>
              <dx-data-grid
              #gridAssignationCommissionConcepts
              [dataSource]="commissionConcepts_dataSource"
              [rowAlternationEnabled]="false"
              [columnAutoWidth]="true"
              [cacheEnabled]="false"
              (onSelectionChanged)="handleSelectionChangedEvent($event)">

              <dxo-paging
                [pageSize]="5">
              </dxo-paging>

              <dxo-pager
                [showPageSizeSelector]="true"
                [allowedPageSizes]="[5,10,20]"
                [showInfo]="true">
              </dxo-pager>

              <dxo-scrolling
                showScrollbar="always">
              </dxo-scrolling>

              <dxo-selection
                [mode]="'single'"
                selectAllMode="page"
                showCheckBoxesMode="none">
              </dxo-selection>

              <dxo-search-panel
                [visible]="true"
                plaseholder="Search...">
              </dxo-search-panel>

                <dxi-column dataField = "Code" [caption]="'Code' | translate" [alignment]="'center'"></dxi-column>
                <dxi-column dataField = "Display_Name" [caption]="'Name' | translate" ></dxi-column>
                <dxi-column dataField = "SaleValuePercent" [caption]="'Commission' | translate: { param : ''}" [format]="'#,##0.## %'"  [alignment]="'center'" [width]="110"></dxi-column>
                <dxi-column dataField = "ReferredPercent" [caption]="'Referred Commission' | translate: { param : ''}" [format]="'#,##0.## %'" [alignment]="'center'" ></dxi-column>
                <dxi-column dataField = "Develop.Name" [caption]="'Develop' | translate" [alignment]="'center'"></dxi-column>
                <!--<dxi-column dataField = "Description" [caption]="'Description' | translate" ></dxi-column>-->

            </dx-data-grid>
            </div>
          </div>

        </div>

        <div class="card-footer-modal">
          <div class="pull-right">

            <dx-button class="btn-avenir-celest-blue"
              [disabled]="selectedCommissionConcept == null"
              [text]="'Accept' | translate"
              (onClick)="buttonAccept(messageResults.Accept)"
              [useSubmitBehavior]="true">
            </dx-button>

            <dx-button class="dlg-button-margin btn-avenir-red"
              [text]="'Cancel' | translate"
              (onClick)="buttonCancel(messageResults.Cancel)">
            </dx-button>
            &nbsp;
          </div>
        </div>
      </div>
    </dx-scroll-view>
  </div>

</dx-popup>
